import { all } from 'redux-saga/effects';

import { sagaAuth } from 'modules/auth';
import { sagaCompanies } from 'modules/companies';
import { sagaUsers } from 'modules/users';

function* rootSaga() {
  yield all([
    sagaAuth(),
    sagaCompanies(),
    sagaUsers(),
  ]);
}

export default rootSaga;
