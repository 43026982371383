// @flow
import React, { PureComponent } from 'react';

import { withControlledProps } from 'components/helpers';
import Popover from 'components/popover';

import callAll from 'utils/callAll';

import type { Props, AnchorPropsFromPopover } from './Tooltip.types';
import s from './Tooltip.style';

class Tooltip extends PureComponent<Props> {

  static defaultProps = {
    position: 'bottom',
    offset: 12,
    appearanceDelay: 300,
  };

  getAnchorProps = (props: Object = {}) => ({
    ...props,
    onMouseEnter: callAll(props.onMouseEnter, this.handleMouseEnter),
    onMouseLeave: callAll(props.onMouseLeave, this.handleMouseLeave),
  });

  appearanceTimer: ?number;

  handleMouseEnter = () => {
    const { onUpdateVisible, appearanceDelay } = this.props;

    this.appearanceTimer = setTimeout(
      () => onUpdateVisible(true),
      appearanceDelay,
    );
  };

  handleMouseLeave = () => {
    const { onUpdateVisible } = this.props;

    clearTimeout(this.appearanceTimer);
    onUpdateVisible(false);
  };

  render() {
    const {
      text,
      offset,
      visible,
      children,
      position,
    } = this.props;
    const { getAnchorProps } = this;

    return (
      <Popover
        visible={visible}
        offset={offset}
        placement={position}
        modifiers={{
          offset: {
            enabled: true,
            offset: `0, ${offset}`,
          },
        }}
        withShadow={false}
        anchor={(anchorProps: AnchorPropsFromPopover) => children({
          ...anchorProps,
          getAnchorProps,
        })}
      >
        {
          !!text &&
          <s.Wrapper>
            {text}
          </s.Wrapper>
        }
      </Popover>
    );
  }

}

export default withControlledProps({
  visible: false,
})(Tooltip);
