// @flow
import * as React from 'react';
import type { Ref } from 'react';

import Input from '../input';
import Select from '../select';
import type { Props } from './ConnectedField.types';

const COMPONENTS_MAP = {
  input: Input,
  select: Select,
};

class ConnectedField extends React.Component<Props> {

  static defaultProps = {
    componentType: 'input',
  };

  fieldRef = (el: Ref<*>) => {
    const { fieldRef, field } = this.props;

    if (!fieldRef) return;

    fieldRef(el, field.name);
  };

  handleChange = (newValue: string) => {
    const {
      field,
      form,
      transform,
    } = this.props;

    form.setFieldValue(
      field.name,
      transform ? transform(newValue, field.value) : newValue,
    );
  };

  render() {
    const {
      field,
      form: { touched, errors },
      componentType,
      transform,
      fieldRef,
      ...props
    } = this.props;
    const { name } = field;
    const Component = COMPONENTS_MAP[componentType];
    const error = touched[name] && errors[name];

    return (
      <Component
        {...field}
        {...props}
        error={error}
        ref={this.fieldRef}
        onChange={this.handleChange}
      />
    );
  }

}

export default ConnectedField;
