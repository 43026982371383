// @flow
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Avatar from 'components/avatar';
import { UserName } from 'components/formatters';
import ContextMenu from 'components/contextMenu';

import { selectorsAuth, actionsAuth } from '../redux';
import type { Props } from './UserInfo.types';
import s from './UserInfo.style';

export const UserInfo = ({
  firstName,
  middleName,
  lastName,
  initials,
  email,
  logout,
}:
Props) => (
  <s.Wrapper>
    <s.Left>
      <s.AvatarWrapper>
        <Avatar
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          initials={initials}
          reserve={email}
          theme="white"
        />
      </s.AvatarWrapper>

      <s.UserNameWrapper>
        <UserName firstName={firstName} lastName={lastName} />
      </s.UserNameWrapper>
    </s.Left>

    <s.Right>
      <ContextMenu
        size={48}
        items={[{
          id: 'logout',
          text: 'Sign Out',
          onSelect: logout,
        }]}
        theme="contrast"
      />
    </s.Right>
  </s.Wrapper>
);

export default connect(
  createStructuredSelector({
    email: selectorsAuth.getUserEmail,
    firstName: selectorsAuth.getUserFirstName,
  }),
  {
    logout: actionsAuth.logout,
  },
)(UserInfo);
