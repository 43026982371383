// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Page from 'components/page';
import ContextMenu from 'components/contextMenu';
import { UsersList, UserModal } from 'modules/users';

import { selectorsCompanies } from '../../redux';
import type { Props, State } from './CompanyUsers.types';

export class CompanyUsers extends PureComponent<Props, State> {

  state = {
    selectedUser: null,
  };

  openUserModal() {
    const { openModal, modalNames } = this.props;
    openModal(modalNames.INVITE_USER);
  }

  handleInviteNewUser = () => {
    this.setState({ selectedUser: null });
    this.openUserModal();
  };

  handleEditUser = (userId: number) => {
    this.setState({ selectedUser: userId });
    this.openUserModal();
  };

  handleCloseModal = () => {
    const { closeModal } = this.props;

    this.setState({ selectedUser: null });
    closeModal();
  }

  render() {
    const {
      id,
      users,
      currentModal,
      modalNames,
    } = this.props;
    const { selectedUser } = this.state;

    return (
      <Page.Section>
        <Page.Header withActions>
          <Page.Title>Users of the Company</Page.Title>
          <ContextMenu
            size={56}
            theme="blue"
            items={[{
              id: 'newUserModal',
              text: 'Add user',
              onSelect: this.handleInviteNewUser,
            }]}
          />
        </Page.Header>

        <UsersList
          users={users}
          companyId={id}
          onEditUser={this.handleEditUser}
        />

        <UserModal
          open={currentModal === modalNames.INVITE_USER}
          onCloseRequest={this.handleCloseModal}
          companyId={id}
          userId={selectedUser}
        />
      </Page.Section>
    );
  }

}

export default connect(
  createStructuredSelector({
    instance: selectorsCompanies.getSelectedCompanyInstance,
    users: selectorsCompanies.getSelectedCompanyUsers,
  }),
)(CompanyUsers);
