// @flow
import React from 'react';

import type { Props } from './FloatingLabel.types';
import s from './FloatingLabel.style';

const FloatingLabel = ({
  active,
  children,
  className,
  disabled,
  error,
}:
Props) => (
  <s.Wrapper
    active={active}
    className={className}
    disabled={disabled}
    error={error}
  >
    {children}
  </s.Wrapper>
);

export default FloatingLabel;
