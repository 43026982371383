// @flow
import React, { PureComponent } from 'react';
import { Manager, Reference, Popper } from 'react-popper';

import type { Props } from './Popover.types';
import s from './Popover.style';

class Popover extends PureComponent<Props> {

  static defaultProps = {
    withShadow: true,
    width: 'auto',
  };

  render() {
    const {
      children,
      visible,
      anchor,
      withShadow,
      width,
      modifiers,
      ...rest
    } = this.props;

    return (
      <Manager>
        <Reference>
          {anchor}
        </Reference>
        {
          visible &&
          <Popper
            {...rest}
            modifiers={{
              preventOverflow: {
                padding: 40,
                boundariesElement: 'viewport',
              },
              ...modifiers,
            }}
          >
            {({ ref, style, placement }) => (
              <s.Wrapper
                innerRef={ref}
                style={style}
                withShadow={withShadow}
                width={width}
                data-placement={placement}
              >
                {children}
              </s.Wrapper>
            )}
          </Popper>
        }
      </Manager>
    );
  }

}

export default Popover;
