// @flow
import { userName } from 'utils/formatters';

import type { Props } from './UserName.types';

const UserName = ({
  firstName, lastName,
}:
Props) => userName(firstName, lastName);

export default UserName;
