// @flow
import * as types from './actionTypes.modals';

export const openModal = (name: string) => ({
  type: types.OPEN_MODAL,
  payload: { name },
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
