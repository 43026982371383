// @flow
import React, { PureComponent } from 'react';

import { Sort } from 'components/icons';

import s from './headCell.style';
import type { Props } from './headCell.types';

const NEXT_DIRECTION_MAP = {
  none: 'asc',
  asc: 'desc',
  desc: 'asc',
};

class HeadCell extends PureComponent<Props> {

  static defaultProps = {
    sortDirection: 'none',
  };

  handleClick = () => {
    const {
      name, canSort, sortDirection, onClick,
    } = this.props;

    if (!onClick) return;

    if (canSort) {
      onClick(name, NEXT_DIRECTION_MAP[sortDirection]);
    } else {
      onClick(name);
    }
  };

  render() {
    const {
      children, width, sortDirection, canSort, ...props
    } = this.props;

    return (
      <s.Wrapper
        {...props}
        canSort={canSort}
        onClick={this.handleClick}
        width={width}
      >
        {children}
        {
          canSort &&
          <s.SortIcon
            active={sortDirection !== 'none'}
            sort={sortDirection}
          >
            <Sort sortDirection={sortDirection} />
          </s.SortIcon>
        }
      </s.Wrapper>
    );
  }

}

export default HeadCell;
