import React from 'react';

/* eslint-disable max-len */
export const Close = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="__close__a"
        d="M11.303 12.01L4 4.707 4.707 4l7.303 7.303 7.647-7.646.707.707-7.646 7.646 7.99 7.99-.708.707-7.99-7.99-7.646 7.647-.707-.707 7.646-7.647z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="__close__b" fill="#fff">
        <use xlinkHref="#__close__a" />
      </mask>
      <use fill="currentColor" xlinkHref="#__close__a" />
      <g fill="currentColor" mask="url(#__close__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
