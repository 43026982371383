// @flow
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, Formik } from 'formik';
import yup from 'yup';
import { createStructuredSelector } from 'reselect';

import { Button } from 'components/buttons';
import { BaseForm, FormRow, FormSection } from 'components/formElements';
import * as formTransforms from 'utils/formTransforms';
import appConfig from 'config';

import { selectorsCompanies, actionsCompanies } from '../redux';
import { selectorsAuth } from '../../auth';
import type { Props, NewCompanyData } from './NewCompanyForm.types';

export class NewCompanyForm extends PureComponent<Props> {

  handleSubmit = (data: NewCompanyData) => {
    this.props.createCompany(data);
  };

  renderOwnerInfo(getFieldProps) {
    if (!appConfig.OWNER_ENABLE) {
      return null;
    }

    return (
      <FormSection title="Owner Info">
        <FormRow>
          <Field
            {...getFieldProps({
              name: 'owner_email',
              floatingLabel: 'Owner Email',
            })}
          />
          <Field
            {...getFieldProps({
              name: 'owner_initials',
              floatingLabel: 'Initials',
              transform: formTransforms.initials,
            })}
          />
        </FormRow>
        <FormRow>
          <Field
            {...getFieldProps({
              name: 'owner_first_name',
              floatingLabel: 'First Name',
            })}
          />
          <Field
            {...getFieldProps({
              name: 'owner_middle_name',
              floatingLabel: 'Middle Name',
            })}
          />
          <Field
            {...getFieldProps({
              name: 'owner_last_name',
              floatingLabel: 'Last Name',
            })}
          />
        </FormRow>
      </FormSection>
    );
  }

  render() {
    const { isSubmitting, error, onCancel, languages } = this.props;
    const initialLang = languages ? languages[0].id : '';

    return (
      <Formik
        onSubmit={this.handleSubmit}
        validationSchema={yup.object().shape({
          name: yup.string().required(),
          instance: yup.string().required(),
          ...(appConfig.OWNER_ENABLE ? {
            owner_email: yup.string().required().email(),
          } : {}),
        })}
        initialValues={{
          name: '',
          instance: '',
          lang: initialLang,
          ...(appConfig.OWNER_ENABLE ? {
            owner_email: '',
            owner_first_name: '',
            owner_middle_name: '',
            owner_last_name: '',
            owner_initials: '',
          } : {}),
        }}
        render={({ values, ...rest }) => (
          <BaseForm
            {...rest}
            isSubmitting={isSubmitting}
            formError={error}
            children={({ getFieldProps }) => (
              <Fragment>
                <FormSection title="Company Info">
                  <FormRow>
                    <Field
                      {...getFieldProps({
                        name: 'name',
                        floatingLabel: 'Company Name',
                      })}
                    />
                    <Field
                      {...getFieldProps({
                        name: 'instance',
                        floatingLabel: 'Instance',
                        transform: formTransforms.domain,
                      })}
                    />
                    <Field
                      {...getFieldProps({
                        componentType: 'select',
                        name: 'lang',
                        floatingLabel: 'Language',
                        options: languages,
                      })}
                    />
                  </FormRow>
                </FormSection>

                {this.renderOwnerInfo(getFieldProps)}

              </Fragment>
            )}
            actions={({ getSubmitButtonProps }) => (
              <Fragment>
                <Button
                  {...getSubmitButtonProps({
                    success: true,
                  })}
                >
                  Create
                </Button>
                <Button
                  type="button"
                  onClick={onCancel}
                  ghost
                >
                  Cancel
                </Button>
              </Fragment>
            )}
          />
        )}
      />
    );
  }

}

export default connect(
  createStructuredSelector({
    isSubmitting: selectorsCompanies.getNewCompanySubmitting,
    error: selectorsCompanies.getNewCompanyError,
    languages: selectorsAuth.getLanguages,
  }),
  {
    createCompany: actionsCompanies.createCompany,
  },
)(NewCompanyForm);
