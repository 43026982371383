// @flow
import React, { Component } from 'react';
import 'inputmask/dist/inputmask/inputmask.numeric.extensions';
import Inputmask from 'inputmask/dist/inputmask/inputmask.date.extensions';

class MaskedInput extends Component<*> {

  static defaultMaskProps = {
    showMaskOnHover: false,
    showMaskOnFocus: false,
    positionCaretOnClick: 'none',
    rightAlign: false,
  };

  componentWillUnmount() {
    if (this.input && this.input.inputmask) {
      this.input.inputmask.remove();
    }
  }

  getRef = (el: ?HTMLElement) => {
    const { innerRef } = this.props;

    this.input = el;

    if (el) {
      this.initMask();
    }

    if (innerRef) {
      innerRef(el);
    }
  }

  getMaskProps() {
    const { maskProps } = this.props;

    return {
      ...MaskedInput.defaultMaskProps,
      ...maskProps,
    };
  }

  handleIncomplete = () => {
    const { onIncomplete, value } = this.props;
    const mask = this.input.inputmask.getemptymask();

    if (onIncomplete) {
      onIncomplete(value === mask);
    }
  };

  initMask() {
    const maskProps = this.getMaskProps();

    if (!maskProps.mask && !maskProps.alias) return;

    this.maskInstance = new Inputmask(maskProps);
    this.maskInstance.mask(this.input);
  }

  input: ?HTMLElement;

  maskInstance: Object;

  render() {
    const { maskProps, innerRef, ...rest } = this.props;

    return (
      <input
        ref={this.getRef}
        {...rest}
      />
    );
  }

}

export default MaskedInput;
