// @flow
import React, { Children } from 'react';

import s from './FormRow.style';
import type { Props } from './FormRow.types';

const FormRow = ({
  children, borders,
}:
Props) => {
  const computedBorders = Object.assign({
    top: true,
    left: true,
    right: true,
    bottom: true,
  }, borders);

  return (
    <s.Row
      topBorder={computedBorders.top}
      rightBorder={computedBorders.right}
      bottomBorder={computedBorders.bottom}
      leftBorder={computedBorders.left}
    >
      {Children.map(children, child => (
        <s.Item>{child}</s.Item>
      ))}
    </s.Row>
  );
};

FormRow.defaultProps = {
  borders: {},
};

export default FormRow;
