// @flow
import React, { PureComponent, Fragment } from 'react';
import Waypoint from 'react-waypoint';

import Spinner from 'components/spinner';

import type { Props, State } from './InfiniteScroll.types';
import s from './InfiniteScroll.style';

class InfiniteScroll extends PureComponent<Props, State> {

  state = {
    page: 1,
  };

  componentDidMount() {
    this.loadMore();
  }

  loadMore = () => {
    const { loadMore } = this.props;
    const { page } = this.state;

    loadMore(page);

    this.setState(state => ({ page: state.page + 1 }));
  };

  renderContent() {
    const { canLoadMore, loading } = this.props;

    if (!canLoadMore) return null;

    if (loading) {
      return (
        <s.SpinnerWrapper>
          <Spinner center />
        </s.SpinnerWrapper>
      );
    }

    return (
      <Waypoint
        topOffset={50}
        onEnter={this.loadMore}
      />
    );
  }

  render() {
    const { children } = this.props;

    return (
      <Fragment>
        {children}
        {this.renderContent()}
      </Fragment>
    );
  }

}

export default InfiniteScroll;
