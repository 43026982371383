// @flow
import { combineReducers } from 'redux';

import type { Action } from 'shared/types';

import * as types from './actionTypes.auth';
import type { ProfileState, LoginError, SettingsState } from './types.auth';

export const loginLoading = (
  state: boolean = false,
  { type }: Action,
) => {
  switch (type) {
    case types.LOGIN_START:
      return true;
    case types.LOGIN_SUCCESS:
    case types.LOGIN_FAIL:
      return false;
    default:
      return state;
  }
};

export const loginError = (
  state: LoginError = null,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.LOGIN_FAIL:
      return payload.error;
    case types.LOGIN_START:
    case types.LOGIN_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const profileLoading = (
  state: boolean = true,
  { type }: Action,
) => {
  switch (type) {
    case types.GET_PROFILE_START:
      return true;
    case types.GET_PROFILE_SUCCESS:
    case types.GET_PROFILE_FAIL:
      return false;
    default:
      return state;
  }
};

export const profile = (
  state: ProfileState = {},
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_PROFILE_SUCCESS:
    case types.LOGIN_SUCCESS:
      return payload;
    case types.LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
};

export const settingsLoading = (
  state: boolean = true,
  { type }: Action,
) => {
  switch (type) {
    case types.GET_SETTINGS_START:
      return true;
    case types.GET_SETTINGS_SUCCESS:
    case types.GET_SETTINGS_FAIL:
      return false;
    default:
      return state;
  }
};


export const settings = (
  state: SettingsState = {},
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_SETTINGS_SUCCESS:
      return payload;
    case types.LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  settings,
  settingsLoading,
  profile,
  profileLoading,
  loginLoading,
  loginError,
});
