// @flow
import styled from 'styled-components';

import { getColor, animations } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.article`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
  height: 100%;

  background: white;
  overflow-y: auto;

  animation: ${animations.fadeIn} .2s both;
`;

s.Content = styled.div`
  width: 768px;
  margin: 0 auto;
  padding: 42px 0;
`;

s.Header = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

s.CloseButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100px;
  padding: 20px;
  padding-right: 0;
  border: none;

  cursor: pointer;
  background: none;
  outline: none;
`;

s.CloseText = styled.span`
  margin-left: 12px;

  font-size: 13px;
  color: ${getColor('stone')};
`;

export default s;
