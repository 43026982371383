// @flow
import styled, { css, keyframes } from 'styled-components';
import { ifProp } from 'styled-tools';
import { ellipsis } from 'polished';

import type { StyledComponents } from 'shared/types/StyledComponents';

import { fieldStyle } from '../formElements.style';

const s: StyledComponents = {};

export const autoFillStart = keyframes`{ from {/* start */}  to {/**/} }`;
export const autoFillCancel = keyframes`{ from {/* cancel */}  to {/**/} }`;

/* eslint-disable indent */
s.Wrapper = styled.label`
  position: relative;

  display: block;
  height: ${fieldStyle.defaultHeight};

  .app-input-field {
    ${fieldStyle.base};

    ${ifProp('ellipsis', css`
      &:not(:focus) {
        ${ellipsis()}
      }
    `)}

    padding: ${ifProp(
      'active',
      `${fieldStyle.activeTopPadding} 16px ${fieldStyle.activeBottomPadding}`,
      '0 16px',
    )};

    &:disabled {
      ${fieldStyle.disabled};
    }

    ${props => !props.error && css`
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        ${fieldStyle.hover};
      }
    `}

    ${props => props.error && css`
      ${fieldStyle.error};
    `}

    &:-webkit-autofill {
        /* Expose a hook for JavaScript when autofill is shown */
        /* JavaScript can capture 'animationstart' events */
        animation-name: ${autoFillStart};
    }

    &:not(:-webkit-autofill) {
        /* Expose a hook for JS onAutoFillCancel */
        /* JavaScript can capture 'animationstart' events */
        animation-name: ${autoFillCancel};
    }
  }
`;

export default s;
