// @flow
import styled from 'styled-components';

import { getColor } from 'utils/styleHelpers';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  width: 256px;

  color: #fff;

  overflow-y: auto;
  background: ${getColor('darkBg')};
`;

s.Header = styled.header`
  padding: 20px 16px;
  flex-shrink: 0;
`;

s.Title = styled.h2`
  margin: 0;

  font-size: inherit;
  line-height: inherit;
  font-weight: normal;
`;

s.NavWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 48px;
`;

s.Footer = styled.footer`
  flex-shrink: 0;
`;

export default s;
