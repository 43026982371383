// @flow
import React, { PureComponent } from 'react';

import type { Props } from './ListItem.types';
import s from './ListItem.style';

class ListItem extends PureComponent<Props> {

  handleClick = (e: SyntheticMouseEvent<*>) => {
    const { onClick, id } = this.props;
    onClick(e, id);
  };

  handleMouseOver = (e: SyntheticMouseEvent<*>) => {
    const { onHover, id } = this.props;
    onHover(e, id);
  };

  handleMouseOut = (e: SyntheticMouseEvent<*>) => {
    const { onLostHover, id } = this.props;
    onLostHover(e, id);
  };

  render() {
    const {
      children,
      oneLine,
      selected,
      ...rest
    } = this.props;

    return (
      <s.Wrapper
        selected={selected}
        tabIndex="0"
        {...rest}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onFocus={this.handleMouseOver}
        onBlur={this.handleMouseOut}
      >
        <s.Text oneLine={oneLine}>
          {children}
        </s.Text>
      </s.Wrapper>
    );
  }

}

export default ListItem;
