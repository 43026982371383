// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ifProp } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled(({ big, light, ...rest }) => <Link {...rest} />)`
  display: inline-block;
  padding: ${ifProp('big', '8px', '0')};

  color: ${ifProp('light', '#fff', getColor('defaultText'))};
  font-size: ${ifProp('big', '28px', '19px')};
  font-weight: 500;
  line-height: ${ifProp('big', 'normal', 1.265)};
  text-decoration: none;
`;

s.Part = styled.span`
  color: ${ifProp('green', getColor('green'), 'inherit')};
`;

export default s;
