// @flow
import styled, { keyframes, css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

import type { StyledComponents } from 'shared/types/StyledComponents';

const spinnerAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const s: StyledComponents = {};

s.Wrapper = styled.svg`
  display: ${ifProp('center', 'block', 'inline-block')};
  width: ${prop('size')}px;
  height: ${prop('size')}px;

  vertical-align: middle;

  animation: ${spinnerAnimation} infinite ${prop('speed')}ms linear;

  ${ifProp('center', css`
    margin-left: auto;
    margin-right: auto;
  `)}
`;

export default s;
