// @flow
import React from 'react';

import { Portal } from 'components/helpers';
import { Close } from 'components/icons';
import Title from 'components/title';

import type { Props } from './Modal.types';
import s from './Modal.style';

const Modal = ({
  open, onCloseRequest, title, children,
}:
Props) => {
  if (!open) {
    return null;
  }

  return (
    <Portal>
      <s.Wrapper>
        <s.Header>
          <s.CloseButton onClick={onCloseRequest}>
            <Close />
            <s.CloseText>Close</s.CloseText>
          </s.CloseButton>
        </s.Header>

        <s.Content>
          {
            !!title &&
            <Title gap={42}>
              {title}
            </Title>
          }

          {children}
        </s.Content>
      </s.Wrapper>
    </Portal>
  );
};

export default Modal;
