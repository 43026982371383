// @flow
import React from 'react';

import type { Props } from './FormSection.types';
import s from './FormSection.style';

const FormSection = ({ title, children }: Props) => (
  <s.Wrapper>
    <s.Title size={17} gap={5}>{title}</s.Title>
    {children}
  </s.Wrapper>
);

export default FormSection;
