// @flow
import React from 'react';

import Logo from 'components/logo';
import Nav from 'components/nav';
import { Companies } from 'components/icons';
import { UserInfo } from 'modules/auth';
import * as routes from 'shared/routes';

import s from './Sidebar.style';

const Sidebar = () => (
  <s.Wrapper>
    <s.Header>
      <s.Title>
        <Logo light />
      </s.Title>
    </s.Header>

    <s.NavWrapper>
      <Nav
        items={[{
          text: 'Companies',
          link: routes.makeAuthenticatedRoute(routes.COMPANIES),
          icon: <Companies />,
        }]}
      />
    </s.NavWrapper>

    <s.Footer>
      <UserInfo />
    </s.Footer>
  </s.Wrapper>
);

export default Sidebar;
