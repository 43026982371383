// @flow
import React, { PureComponent, Fragment } from 'react';

import Table from 'components/table';

import UsersListContextMenu from './usersListContextMenu';
import type { Props } from './UsersListItem.types';

class UsersListItem extends PureComponent<Props> {

  handleEdit = () => {
    const { onEdit, user } = this.props;
    onEdit(user.id);
  };

  renderContextMenu(active) {
    return (
      <Table.Cell actions>
        <UsersListContextMenu
          onEdit={this.handleEdit}
          visible={active === 'hover'}
        />
      </Table.Cell>
    );
  }

  render() {
    const { user } = this.props;

    return (
      <Table.Row>
        {({ active }) => (
          <Fragment>
            <Table.Cell>{user.first_name}</Table.Cell>
            <Table.Cell>{user.last_name}</Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.role}</Table.Cell>
          </Fragment>
        )}
      </Table.Row>
    );
  }

}

export default UsersListItem;
