// @flow
export const GET_USERS_START = 'modules/users/GET_USERS_START';
export const GET_USERS_SUCCESS = 'modules/users/GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'modules/users/GET_USERS_FAIL';

export const INVITE_USER_START = 'modules/users/INVITE_USER_START';
export const INVITE_USER_SUCCESS = 'modules/users/INVITE_USER_SUCCESS';
export const INVITE_USER_FAIL = 'modules/users/INVITE_USER_FAIL';

export const EDIT_USER_START = 'modules/users/EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'modules/users/EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'modules/users/EDIT_USER_FAIL';

export const RESET_PAGINATION = 'modules/users/RESET_PAGINATION';
