// @flow
import React from 'react';

import type { Props } from './ValidationTip.types';
import s from './ValidationTip.style';

const ValidationTip = ({ message, position, className }: Props) => (
  <s.Wrapper
    className={className}
    position={position}
  >
    <s.Balloon>{message}</s.Balloon>
  </s.Wrapper>
);

ValidationTip.defaultProps = {
  position: 'top',
};

export default ValidationTip;
