// @flow
import React from 'react';

import { ValidationTip } from 'components/formElements';
import Spinner from 'components/spinner';

import type { Props } from './Button.types';
import s from './Button.style';

const Button = ({
  children,
  className,
  contrast,
  disabled,
  ghost,
  success,
  size,
  fullWidth,
  error,
  loading,
  ...rest
}:
Props) => (
  <s.Wrapper
    className={className}
    success={success}
    ghost={ghost}
    contrast={contrast}
    disabled={disabled || loading}
    size={size}
    fullWidth={fullWidth}
    {...rest}
  >
    {
      !!error &&
      <ValidationTip message={error} />
    }
    {
      !!loading &&
      <s.SpinnerWrapper>
        <Spinner fillColor="#fff" />
      </s.SpinnerWrapper>
    }
    <s.Text visible={!loading}>
      {children}
    </s.Text>
  </s.Wrapper>
);

Button.defaultProps = {
  size: 'md',
};

export default Button;
