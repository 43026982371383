// @flow
import React from 'react';

import type { Props } from './FlatButton.types';
import s from './FlatButton.style';

const FlatButton = ({
  primary,
  danger,
  disabled,
  filled,
  children,
  ...rest
}:
Props) => (
  <s.Wrapper
    primary={primary}
    danger={danger}
    disabled={disabled}
    filled={filled}
    {...rest}
  >
    {children}
  </s.Wrapper>
);

export default FlatButton;
