// @flow
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ellipsis, hideVisually } from 'polished';

import { resetList } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.nav``;

s.Title = styled.h3`
  ${hideVisually()}
`;

s.Menu = styled.ul`
  ${resetList}
`;

s.Item = styled.li`
  display: block;
`;

s.Link = styled(NavLink).attrs({
  activeClassName: 'nav-item-active',
})`
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 4px;
  padding-right: 24px;

  text-decoration: none;
  color: inherit;

  opacity: 0.5;

  transition: background-color .3s;

  &:not(.nav-item-active):hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.nav-item-active {
    opacity: 1;
  }
`;

s.IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
`;

s.Text = styled.span`
  ${ellipsis()}

  min-width: 0;
`;

export default s;
