// @flow
import React, { Component } from 'react';
import {Switch, Route, Redirect } from 'react-router-dom';

import * as routes from 'shared/routes';
import { AppLayout } from 'components/layouts';

import routesList from './routesList';
import type { Props } from './AppLayoutRoutes.types';
import { createStructuredSelector } from 'reselect';
import { actionsAuth, selectorsAuth } from 'modules/auth';
import Spinner from 'components/spinner';
import { connect } from 'react-redux';
import s from '../../../components/protectedRoute/ProtectedRoute.style';

class AppLayoutRoutes extends Component<Props> {

  componentDidMount() {
    this.props.getSettings();
  }

  makeRoute(route: string) {
    const { match } = this.props;
    return `${match.url}${route}`;
  }

  renderLoader() {
    return (
      <s.LoadingScreen>
        <Spinner size={64} />
      </s.LoadingScreen>
    );
  }

  renderLayout() {
    return (
      <AppLayout
        header={
          <Switch>
            {routesList.map(route => (
              <Route
                key={route.path}
                path={this.makeRoute(route.path)}
                component={() => route.header}
                exact={route.exact}
              />
            ))}
          </Switch>
        }
      >
        <Switch>
          <Route
            exact
            path={this.makeRoute('')}
            render={() => <Redirect to={this.makeRoute(routes.COMPANIES)} />}
          />
          {routesList.map(route => (
            <Route
              key={route.path}
              path={this.makeRoute(route.path)}
              component={route.content}
              exact={route.exact}
            />
          ))}
        </Switch>
      </AppLayout>
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return this.renderLayout();
  }

}

const mapStateToProps = createStructuredSelector({
  loading: selectorsAuth.getSettingsLoading,
});

const mapDispatchToProps = {
  getSettings: actionsAuth.getSettings,
};

export default (connect(mapStateToProps, mapDispatchToProps)(AppLayoutRoutes));
