// @flow
import React, { PureComponent } from 'react';
import type { ComponentType } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import capitalize from 'lodash/capitalize';

import getDisplayName from 'utils/getDisplayName';

import type {
  ControlledProps,
  State,
  Handlers,
  Props,
} from './withControlledProps.types';

const withControlledProps =
(controlledProps: ControlledProps = {}) =>
  (WrappedComponent: ComponentType<any>): ?ComponentType<any> => {
    if (!WrappedComponent) {
      return null;
    }

    class WithControlledProps extends PureComponent<Props, State> {

      static displayName = `WithControlledProps(${getDisplayName(WrappedComponent)})`;

      static defaultProps = {
        innerRef: null,
      };

      state: State = this.getInitialState();

      getInitialState(): State {
        const entries = Object.entries(controlledProps);

        return entries.reduce(
          (acc, [prop, defaultValue]) => {
            if (!this.isConnected(prop)) {
              acc[prop] = defaultValue;
            }
            return acc;
          },
          {},
        );
      }

      getUpdateHandlers(): Handlers {
        const propNames = Object.keys(controlledProps);
        const handlers = {};

        propNames.forEach((prop) => {
          const handlerName = `onUpdate${capitalize(prop)}`;

          if (this.isConnected(prop)) {
            handlers[handlerName] = this.props[handlerName];
          } else {
            if (!this.updateMethods[handlerName]) {
              this.updateMethods[handlerName] = (value) => {
                this.setState({ [prop]: value });

                if (this.props[handlerName]) {
                  this.props[handlerName](value);
                }
              };
            }

            handlers[handlerName] = this.updateMethods[handlerName];
          }
        });

        return handlers;
      }

      updateMethods: Handlers = {};

      isConnected(prop): bool {
        return this.props[prop] !== undefined;
      }

      render() {
        const { innerRef, ...props } = this.props;

        return (
          <WrappedComponent
            {...props}
            {...this.state}
            {...this.getUpdateHandlers()}
            ref={innerRef}
          />
        );
      }

    }

    hoistNonReactStatic(WithControlledProps, WrappedComponent);

    return hoistNonReactStatic(WithControlledProps, WrappedComponent);
  };

export default withControlledProps;
