import { injectGlobal } from 'styled-components';
import 'normalize.css';
import 'animate.css/animate.css';

import RobotoBold from './assets/fonts/Roboto-Bold.ttf';
import RobotoMedium from './assets/fonts/Roboto-Medium.ttf';
import RobotoRegular from './assets/fonts/Roboto-Regular.ttf';
import RobotoLight from './assets/fonts/Roboto-Light.ttf';

import theme from './theme';

// Global style
// eslint-disable-next-line no-unused-expressions
injectGlobal`
  @font-face {
    font-family: "Roboto";
    font-weight: 700;
    src: url("${RobotoBold}");
  }

  @font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: url("${RobotoMedium}");
  }

  @font-face {
    font-family: "Roboto";
    src: url("${RobotoRegular}");
  }

  @font-face {
    font-family: "Roboto";
    font-weight: 300;
    src: url("${RobotoLight}");
  }

  * {
    -webkit-font-smoothing: antialiased;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: ${theme.fonts.main};
    font-size: 13px;
    line-height: 1.23;
    color: ${theme.colors.defaultText};
  }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
  }
`;
