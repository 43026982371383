// @flow
import React from 'react';

import s from './Logo.style';
import type { Props } from './Logo.types';

const toUpperCaseFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const projectNameFromHost = () => {
  const arrayFromHost = window.location.hostname.split('.');
  const [last] = arrayFromHost.slice(-1);
  const [secondToLast] = arrayFromHost.slice(-2);
  const capitalizeSecond = toUpperCaseFirst(secondToLast);
  return `${capitalizeSecond}.${last}`;
};

const Logo = ({ big, light, className }: Props) => (
  <s.Wrapper
    big={big}
    light={light}
    className={className}
    to="/"
  >
    <s.Part>{projectNameFromHost()}</s.Part>
  </s.Wrapper>
);

export default Logo;
