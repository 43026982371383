// @flow
import { takeLatest, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { ExtractReturnType } from 'shared/types';
import * as routes from 'shared/routes';
import history from 'shared/history';
import { actionsUsers } from 'modules/users';
import { actionsModals } from 'modules/modals';
import { NotificationsProvider } from 'modules/notifications';

import * as actions from './actions.companies';
import * as types from './actionTypes.companies';
import * as services from './services.companies';
import * as transforms from './transforms.companies';

type GetCompaniesAction = $Call<ExtractReturnType, typeof actions.getCompanies>;
type CreateCompanyAction = $Call<ExtractReturnType, typeof actions.createCompany>;
type GetCompanyDetailsAction = $Call<ExtractReturnType, typeof actions.getCompanyDetails>;
type ChangeCompanyDataAction = $Call<ExtractReturnType, typeof actions.changeCompanyData>;

export function* getCompanies({ payload }: GetCompaniesAction): Saga<*> {
  try {
    const { data } = yield call(services.getCompanies, payload.page);
    yield put(actions.getCompaniesSuccess({
      ...transforms.normalizeCompaniesList(data.data),
      pagination: data.meta.pagination,
    }));
  } catch (err) {
    yield put(actions.getCompaniesFail(err.message));
  }
}

export function* createCompany({ payload }: CreateCompanyAction): Saga<*> {
  try {
    const { data } = yield call(services.createCompany, payload);
    yield put(actions.createCompanySuccess(data));
    yield put(actionsModals.closeModal());
    history.push(routes.makeAuthenticatedRoute(`${routes.COMPANIES}/${data.id}`));
  } catch (error) {
    let { message } = error;

    if (error.response && error.response.status === 400) {
      message = error.response.data;
    }

    yield put(actions.createCompanyFail(message));
  }
}

export function* getCompanyDetails({ payload }: GetCompanyDetailsAction): Saga<*> {
  if (!payload.id) return;

  yield put(actionsUsers.resetPagination());

  try {
    const { data } = yield call(services.getCompanyDetails, payload.id);
    yield put(actions.getCompanyDetailsSuccess(data));
  } catch (err) {
    yield put(actions.getCompanyDetailsFail(err.message));
  }
}

export function* changeCompanyData({ payload }: ChangeCompanyDataAction): Saga<*> {
  try {
    const { data } = yield call(services.changeCompanyData, payload.id, payload.data);
    yield put(actions.changeCompanyDataSuccess(payload.id, data));
    NotificationsProvider.showSuccess('Change saved');

  } catch (err) {
    yield put(actions.changeCompanyDataFail(payload.id, err.message));
    NotificationsProvider.show({
      title: 'Change Company Data',
      message: 'An error occured. Please try again.',
    });
  }
}

// All sagas to be loaded
export default function* (): Saga<*> {
  yield takeLatest(types.GET_COMPANIES_START, getCompanies);
  yield takeLatest(types.CREATE_COMPANY_START, createCompany);
  yield takeLatest(types.GET_COMPANY_DETAILS_START, getCompanyDetails);
  yield takeLatest(types.CHANGE_COMPANY_DATA_START, changeCompanyData);
}
