// @flow
const defaultTheme = {
  colors: {
    darkBg: '#21303f',
    defaultText: '#22313f',
    globalLines: '#e3e3e3',
    lightBlue: '#f6f9fd',
    blue: '#4183d7',
    green: '#59b671',
    red: '#d0021b',
    error: '#e74c3c',
    yellow: '#fdc129',
    lightGray: '#f3f3f3',
    mdGray: '#9098a0',
    darkGray: '#57616c',
    silver: '#a6b1ba',
    stone: '#6c7a89',
    brightOrange: '#ff6400',
  },
  fonts: {
    main: 'Roboto, Helvetica, Arial, sans-serif',
  },
};

export default defaultTheme;
