// @flow
import styled, { css } from 'styled-components';
import { prop, ifProp, switchProp, withProp } from 'styled-tools';
import { rgba } from 'polished';

import { getColor } from 'utils/styleHelpers';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

const activeBgColor = css`
  ${switchProp('colorTheme', {
    blue: withProp(getColor('defaultText'), color => rgba(color, 0.05)),
    gray: withProp(getColor('defaultText'), color => rgba(color, 0.05)),
    contrast: 'rgba(255, 255, 255, 0.05)',
  })}
`;

s.HoverArea = styled.span`
  display: flex;
  padding: 4px;

  background: ${ifProp('active', activeBgColor, 'transparent')};

  transition: background-color .3s;
`;

s.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${prop('size', 32)}px;
  height: ${prop('size', 32)}px;
  border: none;

  color: ${ifProp(
    'active',
    switchProp('colorTheme', {
      blue: getColor('blue'),
      gray: getColor('defaultText'),
      contrast: '#fff',
    }),
    switchProp('colorTheme', {
      blue: withProp(getColor('blue'), color => rgba(color, 0.75)),
      gray: withProp(getColor('defaultText'), color => rgba(color, 0.75)),
      contrast: 'rgba(255, 255, 255, 0.5)',
    }),
  )};

  background: none;
  outline: none;
  cursor: pointer;
  visibility: ${ifProp('visible', 'visible', 'hidden')};

  transition: color .3s;

  &:hover {
    ${s.HoverArea} {
      background: ${activeBgColor};
    }
  }
`;

export default s;
