// @flow
import { request } from 'utils/http';
import * as endpoints from 'shared/endpoints';

import type { NewCompanyData, Company } from '../companies.types';
import type { GetCompaniesResponse, ChangeCompanyData } from './types.companies';

export const getCompanies = (page: number): Promise<{ data: GetCompaniesResponse }> =>
  request.get(endpoints.COMPANIES, { params: { page } });

export const createCompany = (data: NewCompanyData): Promise<{ data: Company }> =>
  request.post(endpoints.COMPANIES, data);

export const getCompanyDetails = (id: number): Promise<{ data: Company }> =>
  request.get(`${endpoints.COMPANIES}/${id}`);

export const changeCompanyData = (id: number, data: ChangeCompanyData): Promise<*> =>
  request.put(`${endpoints.COMPANIES}/${id}`, data);
