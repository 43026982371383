// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { ExtractReturnType } from 'shared/types';

import * as actions from './actions.auth';
import * as types from './actionTypes.auth';
import * as services from './services.auth';
import type { Profile } from './types.auth';

type ProfileResponse = { data: Profile };
type SettingsResponse = { data: Settings };
type LoginAction = $Call<ExtractReturnType, typeof actions.login>;

export function* login({ payload }: LoginAction): Saga<*> {
  try {
    const response: ProfileResponse = yield call(services.login, payload.data);
    yield put(actions.loginSuccess(response.data));
  } catch (error) {
    let { message } = error;

    if (error.response && error.response.status === 400) {
      message = error.response.data;
    }

    yield put(actions.loginFail(message));
  }
}

export function* logout(): Saga<*> {
  try {
    yield call(services.logout);
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFail(error.message));
  }
}

export function* getProfile(): Saga<*> {
  try {
    const response: ProfileResponse = yield call(services.getProfile);
    yield put(actions.getProfileSuccess(response.data));
  } catch (error) {
    yield put(actions.getProfileFail(error.message));
  }
}

export function* getSettings(): Saga<*> {
  try {
    const response: SettingsResponse = yield call(services.getSettings);
    yield put(actions.getSettingsSuccess(response.data));
  } catch (error) {
    yield put(actions.getSettingsFail(error.message));
  }
}

// All sagas to be loaded
export default function* (): Saga<*> {
  yield takeLatest(types.LOGIN_START, login);
  yield takeLatest(types.LOGOUT_START, logout);
  yield takeLatest(types.GET_PROFILE_START, getProfile);
  yield takeLatest(types.GET_SETTINGS_START, getSettings);
}
