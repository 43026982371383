import { combineReducers } from 'redux';

import { reducerAuth as auth } from 'modules/auth';
import { reducerCompanies as companies } from 'modules/companies';
import { reducerModals as modals } from 'modules/modals';
import { reducerUsers as users } from 'modules/users';

const rootReducer = combineReducers({
  auth,
  companies,
  modals,
  users,
});

export default rootReducer;
