// @flow
import React, { PureComponent } from 'react';

import callAll from 'utils/callAll';

import ListItem, { listItemsTypes } from './listItem';
import type { Props, State } from './List.types';
import s from './List.style';

class List extends PureComponent<Props, State> {

  static defaultProps = {
    width: '100%',
    oneLine: false,
  };

  state = {
    hovered: null,
  };

  isSelected(id: listItemsTypes.ItemId) {
    const { hovered } = this.state;
    const { selected } = this.props;

    if (!hovered) return id === selected;

    return id === hovered;
  }

  handleHover = (e: SyntheticMouseEvent<*>, id: listItemsTypes.ItemId) => {
    this.setState({ hovered: id });
  };

  handleHoverLost = (e: SyntheticMouseEvent<*>, id: listItemsTypes.ItemId) => {
    this.setState({ hovered: null });
  };

  handleItemClick = (e: SyntheticMouseEvent<*>, id: listItemsTypes.ItemId) => {
    const { onItemSelect } = this.props;

    if (onItemSelect) {
      onItemSelect(e, id);
    }
  };

  render() {
    const { items, oneLine, width } = this.props;

    return (
      <s.Wrapper width={width}>
        {items.map(item => (
          <ListItem
            key={item.id}
            id={item.id}
            oneLine={oneLine}
            selected={this.isSelected(item.id)}
            onHover={this.handleHover}
            onLostHover={this.handleHoverLost}
            onClick={callAll(item.onSelect, this.handleItemClick)}
          >
            {item.text}
          </ListItem>
        ))}
      </s.Wrapper>
    );
  }

}

export default List;
