// @flow
import React from 'react';

import * as routes from 'shared/routes';
import Header from 'components/header';
import { CompaniesPage, CompanyDetailsPage } from 'modules/companies';

export default [{
  path: routes.COMPANIES,
  header: <Header.Title>Companies</Header.Title>,
  content: CompaniesPage,
  exact: true,
}, {
  path: `${routes.COMPANIES}/:id`,
  header: <Header.Title>Company Details</Header.Title>,
  content: CompanyDetailsPage,
  exact: false,
}];
