// @flow
import React from 'react';
import { Field } from 'formik';

import { FormRow, ConnectedField } from 'components/formElements';
import Page from 'components/page';

const CompanyInfo = ({ languages }) => (
  <Page.Section>
    <Page.Header>
      <Page.Title>Company</Page.Title>
    </Page.Header>

    <FormRow borders={{ top: false, left: false, right: false }}>
      <Field
        component={ConnectedField}
        componentType="input"
        floatingLabel="Name"
        name="name"
        ellipsis
      />
      <Field
        component={ConnectedField}
        componentType="input"
        floatingLabel="Instance"
        name="instance"
        disabled
        ellipsis
      />
      <Field
        component={ConnectedField}
        componentType="input"
        floatingLabel="Registration"
        name="created_at"
        disabled
        ellipsis
      />
      <Field
        component={ConnectedField}
        componentType="select"
        floatingLabel="Language"
        options={languages}
        name="lang"
      />
    </FormRow>
  </Page.Section>
);

export default CompanyInfo;
