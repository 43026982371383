// @flow
import { createSelector } from 'reselect';

import { selectorsUsers } from 'modules/users';
import * as formatters from 'utils/formatters';
import appConfig from 'config';

import type { State } from './types.companies';

export const getCompaniesDomain = (state: Object): State => state.companies;

export const getCompaniesById = createSelector(
  getCompaniesDomain,
  state => state.byId,
);

export const getCompaniesIds = createSelector(
  getCompaniesDomain,
  state => state.ids,
);

export const getCompaniesList = createSelector(
  [getCompaniesById, getCompaniesIds],
  (byId, ids) => ids.map(id => byId[id]),
);

export const getPagination = createSelector(
  getCompaniesDomain,
  state => state.pagination,
);

export const getCanLoadMore = createSelector(
  getPagination,
  (pagination) => {
    if (!pagination) return false;

    const { total_pages: total, current_page: current } = pagination;

    return current < total;
  },
);

export const getLoadingList = createSelector(
  getCompaniesDomain,
  state => state.loadingList,
);

export const getNewCompanySubmitting = createSelector(
  getCompaniesDomain,
  state => state.newCompanySubmitting,
);

export const getNewCompanyError = createSelector(
  getCompaniesDomain,
  state => state.newCompanyError,
);

export const getLoadingCompany = createSelector(
  getCompaniesDomain,
  state => state.loadingCompany,
);

export const getSelectedCompanyId = createSelector(
  getCompaniesDomain,
  state => state.selectedCompany,
);

export const getSelectedCompany = createSelector(
  [getSelectedCompanyId, getCompaniesById],
  (id, byId) => id && byId[id] ? byId[id] : {},
);

export const getSelectedCompanyUserIds = createSelector(
  getSelectedCompany,
  company => company.users || [],
);

export const getSelectedCompanyInstance = createSelector(
  getSelectedCompany,
  company => company.instance || '',
);

export const getCompanyFormInitialValues = createSelector(
  getSelectedCompany,
  company => ({
    name: company.name || '',
    instance: formatters.instance(company.instance || ''),
    lang: company.lang || '',
    created_at: company.created_at || '',
  }),
);

export const getSelectedCompanyUsers = createSelector(
  [selectorsUsers.getById, getSelectedCompanyUserIds],
  (byId, ids) => ids.map(id => byId[id]),
);
