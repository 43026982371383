// @flow
import React from 'react';

import Modal from 'components/modal';

import { InviteUserForm, EditUserForm } from '../userForm';
import type { Props } from './UserModal.types';

const UserModal = ({
  open,
  onCloseRequest,
  companyId,
  userId,
}:
Props) => (
  <Modal
    title={`${userId ? 'Edit' : 'Invite New'} User`}
    open={open}
    onCloseRequest={onCloseRequest}
  >
    {userId ?
      <EditUserForm
        userId={userId}
        companyId={companyId}
        onCancel={onCloseRequest}
      />
      :
      <InviteUserForm
        companyId={companyId}
        onCancel={onCloseRequest}
      />
    }
  </Modal>
);

export default UserModal;
