// @flow
import React from 'react';

import Sidebar from 'components/sidebar';
import Header from 'components/header';

import type { Props } from './AppLayout.types';
import s from './AppLayout.style';

const AppLayout = ({ children, header }: Props) => (
  <s.Wrapper>
    <Sidebar />
    <s.Main>
      <Header>{header}</Header>
      <s.Content>
        {children}
      </s.Content>
    </s.Main>
  </s.Wrapper>
);

export default AppLayout;
