// @flow
import React, { Component, Fragment } from 'react';
import { ToastContainer, style, toast } from 'react-toastify';

import Notification from './notification';
import NotificationTransition from './notificationTransition';
import type { Props, NotificationOptions } from './NotificationsProvider.types';

const AUTO_CLOSE_TIME = 10000;

style({
  width: 'auto',
  BOTTOM_LEFT: {
    bottom: '32px',
    left: '32px',
  },
});

class NotificationsProvider extends Component<Props> {

  static show({
    autoClose,
    mini,
    ...rest
  }:
  NotificationOptions) {
    toast(
      <Notification
        mini={mini}
        {...rest}
      />,
      {
        autoClose: autoClose || mini ? AUTO_CLOSE_TIME : false,
      },
    );
  }

  static showSuccess(message: string) {
    NotificationsProvider.show({
      type: 'success',
      icon: 'done',
      mini: true,
      title: message,
    });
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
        <ToastContainer
          position={toast.POSITION.BOTTOM_LEFT}
          autoClose={false}
          closeButton={false}
          newestOnTop
          hideProgressBar
          transition={NotificationTransition}
          className={{ padding: 0 }}
          toastClassName={{
            marginTop: '32px',
            marginBottom: 0,
            padding: 0,
            boxShadow: 'none',
            background: 'none',
            overflow: 'visible',
          }}
          bodyClassName={{ flex: '0 1 auto' }}
        />
      </Fragment>
    );
  }

}

export default NotificationsProvider;
