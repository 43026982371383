// @flow
import styled, { css } from 'styled-components';
import { triangle } from 'polished';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  position: absolute;
  left: 50%;
  z-index: 10;

  display: flex;
  justify-content: center;
  width: 200px;
  padding-bottom: 8px;

  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  text-align: center;
  color: #fff;
  text-transform: none;

  transform: translateX(-50%);

  &::after {
  ${props => triangle({
    width: '12px',
    height: '8px',
    pointingDirection: 'bottom',
    foregroundColor: getColor('brightOrange')(props),
  })}

    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;

    transform: translateX(-50%);
  }

  ${(props) => {
    if (props.position === 'top') {
      return css`
        bottom: 100%;

        margin-bottom: 4px;
      `;
    }

    if (props.position === 'bottom') {
      return css`
        top: 100%;

        margin-top: 4px;
      `;
    }

    return '';
  }}
`;

s.Balloon = styled.div`
  padding: 4px 8px;

  background-color: ${getColor('brightOrange')};
  border-radius: 2px;
`;

export default s;
