// @flow
import { takeLatest, takeEvery, select, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { ExtractReturnType } from 'shared/types';
import { actionsModals } from 'modules/modals';

import * as actions from './actions.users';
import * as types from './actionTypes.users';
import * as services from './services.users';
import * as transforms from './transforms.users';
import * as selectors from './selectors.users';

type GetUsersAction = $Call<ExtractReturnType, typeof actions.getUsers>;
type InviteUserAction = $Call<ExtractReturnType, typeof actions.inviteUser>;
type EditUserAction = $Call<ExtractReturnType, typeof actions.editUser>;

export function* getUsers({ payload }: GetUsersAction): Saga<*> {
  try {
    const { data } = yield call(services.getUsers, payload.companyId, payload.page);
    yield put(actions.getUsersSuccess({
      companyId: payload.companyId,
      pagination: data.meta.pagination,
      ...transforms.normalizeUsersList(data.data),
    }));
  } catch (error) {
    yield put(actions.getUsersFail(error.message));
  }
}

export function* inviteUser({ payload }: InviteUserAction): Saga<*> {
  try {
    const { data } = yield call(services.inviteUser, payload.companyId, payload.data);

    if (data.role === 'owner') {
      yield put(actions.getUsers(payload.companyId, 1));
    }

    yield put(actions.inviteUserSuccess(payload.companyId, data));
    yield put(actionsModals.closeModal());
  } catch (error) {
    let message = 'User can not be registered';

    if (error.response && error.response.status === 400) {
      message = error.response.data;
    }

    yield put(actions.inviteUserFail(payload.companyId, message));
  }
}

export function* editUser({ payload }: EditUserAction): Saga<*> {
  try {
    const { data } = yield call(
      services.editUser, payload.userId, payload.companyId, payload.data,
    );

    const users = yield select(selectors.getById);
    const userData = users[payload.userId];

    // если пользователю выставили роль 'owner',
    // то у предыдущего владельца должна поменяться роль
    // на admin
    if (userData.role !== 'owner' && data.role === 'owner') {
      yield put(actions.getUsers(payload.companyId, 1));
    }

    yield put(actions.editUserSuccess(payload.userId, data));
    yield put(actionsModals.closeModal());
  } catch (error) {
    let message = 'Fail to change user';

    if (error.response && error.response.status === 400) {
      message = error.response.data;
    }

    yield put(actions.editUserFail(payload.userId, message));
  }
}

// All sagas to be loaded
export default function* (): Saga<*> {
  yield takeLatest(types.GET_USERS_START, getUsers);
  yield takeLatest(types.INVITE_USER_START, inviteUser);
  yield takeEvery(types.EDIT_USER_START, editUser);
}
