// @flow
import React from 'react';

import ContextMenu from 'components/contextMenu';

import type { Props } from './UsersListContextMenu.types';

const UsersListContextMenu = ({ onEdit, ...rest }: Props) => (
  <ContextMenu
    {...rest}
    items={[{
      id: 'edit',
      text: 'Edit user',
      onSelect: onEdit,
    }]}
  />
);

export default UsersListContextMenu;
