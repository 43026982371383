// @flow
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectorsModals, actionsModals } from '../redux';
import * as modalNames from '../modalNames';
import type { Props } from './ModalProvider.types';

const ModalProvider = ({
  children,
  currentModal,
  openModal,
  closeModal,
}:
Props) => children({
  currentModal, openModal, closeModal, modalNames,
});

export default connect(
  createStructuredSelector({
    currentModal: selectorsModals.getCurrentModal,
  }),
  {
    openModal: actionsModals.openModal,
    closeModal: actionsModals.closeModal,
  },
)(ModalProvider);
