// @flow
import React from 'react';

import { Attention, Done, Clock } from 'components/icons';

import type { Props } from './NotificationIcon.types';

const iconsMap = {
  attention: Attention,
  done: Done,
  wait: Clock,
};

const NotificationIcon = ({ icon }: Props) => {
  const Icon = iconsMap[icon];

  if (!Icon) {
    return null;
  }

  return <Icon />;
};

export default NotificationIcon;
