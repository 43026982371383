// @flow
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectorsAuth } from 'modules/auth';
import Spinner from 'components/spinner';
import * as routes from 'shared/routes';

import s from './ProtectedRoute.style';

import type { Props } from './ProtectedRoute.types';

export const ProtectedRoute = ({
  authenticated,
  protection,
  loading,
  component: Component,
  render,
  ...rest
}:
Props) => (
  <Route
    {...rest}
    render={(routerProps) => {
      if (loading) {
        return (
          <s.LoadingScreen>
            <Spinner size={64} />
          </s.LoadingScreen>
        );
      }

      if (protection === 'authenticated' && !authenticated) {
        return (
          <Redirect
            to={{
              pathname: routes.NOT_AUTHENTICATED,
              state: { from: routerProps.location },
            }}
          />
        );
      }

      if (protection === 'not authenticated' && authenticated) {
        return (
          <Redirect
            to={{
              pathname: routes.AUTHENTICATED,
              state: { from: routerProps.location },
            }}
          />
        );
      }

      if (Component) {
        return <Component {...routerProps} />;
      }

      if (render) {
        return render(routerProps);
      }

      return null;
    }}
  />
);

/* eslint-disable react/default-props-match-prop-types */
ProtectedRoute.defaultProps = {
  protection: 'authenticated',
};
/* eslint-enable */

const mapStateToProps = createStructuredSelector({
  loading: selectorsAuth.getProfileLoading,
  authenticated: selectorsAuth.isUserAuthenticated,
});

export default connect(mapStateToProps)(ProtectedRoute);
