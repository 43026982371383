// @flow
import type { Action } from 'shared/types';

import * as types from './actionTypes.modals';

export default (
  state: ?string = null,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.OPEN_MODAL:
      return payload.name;

    case types.CLOSE_MODAL:
      return null;

    default:
      return state;
  }
};
