// @flow
import { css } from 'styled-components';

import { getColor, shadowBorder } from 'utils/styleHelpers';

export const fieldStyle = {
  defaultHeight: '48px',

  activeTopPadding: '24px',
  activeBottomPadding: '8px',

  base: css`
    ${shadowBorder({ top: false, left: false, right: false })}

    width: 100%;
    height: 100%;
    border: none;

    background-color: #fff;
    outline: none;

    transition: background-color .2s;
  `,

  disabled: css`
    background-color: #fff;
    cursor: default;
  `,

  hover: css`
    background-color: ${getColor('lightBlue')};
  `,

  error: css`
    background-color: rgba(255, 245, 242, 0.8);
  `,

  pressed: css`
    background-color: #eef5fb;
  `,
};
