// @flow
import type { ComponentType } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import type { Props } from './FormActions.types';

const FormActions: ComponentType<Props> = styled.div`
  margin-top: ${prop('gap')}px;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

FormActions.defaultProps = {
  gap: 40,
};

export default FormActions;
