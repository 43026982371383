// @flow
import { createSelector } from 'reselect';

import type { State } from './types.users';

export const getUsersDomain = (state: Object): State => state.users;

export const getById = createSelector(
  getUsersDomain,
  state => state.byId,
);

export const getIds = createSelector(
  getUsersDomain,
  state => state.ids,
);

export const getLoadingUsers = createSelector(
  getUsersDomain,
  state => state.loadingUsers,
);

export const getPagination = createSelector(
  getUsersDomain,
  state => state.pagination,
);

export const getCanLoadMore = createSelector(
  getPagination,
  (pagination) => {
    if (!pagination) return true;

    const { total_pages: total, current_page: current } = pagination;

    return current < total;
  },
);

export const getLoading = createSelector(
  getUsersDomain,
  state => state.loading,
);

export const getErrors = createSelector(
  getUsersDomain,
  state => state.errors,
);

export const getInviteUserSubmitting = createSelector(
  getLoading,
  loading => loading.invite,
);

export const getInviteUserError = createSelector(
  getErrors,
  errors => errors.invite,
);

export const getUserId = (state: State, props: { userId: number }) => props.userId;

export const makeGetUserLoading = () => createSelector(
  [getLoading, getUserId],
  (loading, userId) => loading[userId],
);

export const makeGetUserErrors = () => createSelector(
  [getErrors, getUserId],
  (errors, userId) => errors[userId],
);

export const makeGetUserById = () => createSelector(
  [getById, getUserId],
  (byId, userId) => byId[userId],
);
