import React from 'react';

/* eslint-disable max-len */
export const Dots = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="__dots__a"
        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="__dots__b" fill="#fff">
        <use xlinkHref="#__dots__a" />
      </mask>
      <use fill="#999" xlinkHref="#__dots__a" />
      <g fill="currentColor" mask="url(#__dots__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
