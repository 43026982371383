// @flow
export const GET_COMPANIES_START = 'modules/companies/GET_COMPANIES_START';
export const GET_COMPANIES_SUCCESS = 'modules/companies/GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'modules/companies/GET_COMPANIES_FAIL';

export const CREATE_COMPANY_START = 'modules/companies/CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'modules/companies/CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'modules/companies/CREATE_COMPANY_FAIL';
export const CREATE_COMPANY_CANCEL = 'modules/companies/CREATE_COMPANY_CANCEL';

export const GET_COMPANY_DETAILS_START = 'modules/companies/GET_COMPANY_DETAILS_START';
export const GET_COMPANY_DETAILS_SUCCESS = 'modules/companies/GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_FAIL = 'modules/companies/GET_COMPANY_DETAILS_FAIL';

export const CHANGE_COMPANY_DATA_START = 'modules/companies/CHANGE_COMPANY_DATA_START';
export const CHANGE_COMPANY_DATA_SUCCESS = 'modules/companies/CHANGE_COMPANY_DATA_SUCCESS';
export const CHANGE_COMPANY_DATA_FAIL = 'modules/companies/CHANGE_COMPANY_DATA_FAIL';
