// @flow

// FONE-1278 @TODO {
const FIXED_PART = `.${window.location.hostname.replace(/^[a-z]+\.(.+)/, '$1')}`;
// FONE-1278 @TODO }


export const getInstanceFixedPart = () => FIXED_PART;
export const instance = (inst: string = '') => `${inst}${getInstanceFixedPart()}`;
export const getInstance = (fullDomain: string) =>
  fullDomain.replace(getInstanceFixedPart(), '');
