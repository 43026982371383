// @flow
import { createSelector } from 'reselect';

import type { State } from './types.auth';

export const getAuthDomain = (state: Object): State => state.auth;

export const getLoginLoading = createSelector(
  getAuthDomain,
  state => state.loginLoading,
);

export const getLoginError = createSelector(
  getAuthDomain,
  state => state.loginError,
);

export const getProfileLoading = createSelector(
  getAuthDomain,
  state => state.profileLoading,
);

export const getSettingsLoading = createSelector(
  getAuthDomain,
  state => state.settingsLoading,
);

export const getSettings = createSelector(
  getAuthDomain,
  state => state.settings,
);

export const getLanguages = createSelector(
  getSettings,
  (settings) => {
    if (!(settings && settings.allowedLanguages)) return null;

    const { allowedLanguages } = settings;

    return Object.keys(allowedLanguages).map((id) => {
      return { id, text: allowedLanguages[id] }
    });
  }
);

export const getProfile = createSelector(
  getAuthDomain,
  state => state.profile,
);

export const getUserId = createSelector(
  getProfile,
  profile => profile.id || null,
);

export const isUserAuthenticated = createSelector(
  getUserId,
  id => id !== null,
);

export const getUserEmail = createSelector(
  getProfile,
  profile => profile.email || null,
);

export const getUserFirstName = createSelector(
  getProfile,
  profile => profile.name || null,
);
