// @flow
import styled from 'styled-components';
import { ellipsis } from 'polished';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  display: flex;
  padding-left: 4px;
`;

s.Left = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;

  opacity: 0.5;
`;

s.Right = styled.div`
  flex-shrink: 0;
`;

s.AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 48px;
  width: 48px;
`;

s.UserNameWrapper = styled.div`
  ${ellipsis()}

  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

export default s;
