// @flow
import React, { PureComponent } from 'react';

import FloatingLabel from '../floatingLabel';
import ValidationTip from '../validationTip';
import MaskedInput from '../maskedInput';
import type { Props, State } from './Input.types';
import s, { autoFillStart } from './Input.style';

class Input extends PureComponent<Props, State> {

  static defaultProps = {
    value: '',
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const hasValue = !!nextProps.value;

    if (hasValue === prevState.hasValue) return null;
    return { hasValue };
  }

  state = {
    inFocus: false,
    hasValue: !!this.props.value,
  };

  setCaretPosition(pos: number) {
    if (!this.input) return;
    this.input.setSelectionRange(pos, pos);
  }

  input: ?HTMLInputElement;

  focus() {
    if (!this.input) return;
    this.input.focus();
  }

  blur() {
    if (!this.input) return;
    this.input.blur();
  }

  handleFocus = (e: SyntheticInputEvent<*>) => {
    const { onFocus } = this.props;

    this.setState({ inFocus: true });

    if (onFocus) {
      onFocus(e);
    }
  };

  handleBlur = (e: SyntheticInputEvent<*>) => {
    const { onBlur } = this.props;

    this.setState({ inFocus: false });

    if (onBlur) {
      onBlur(e);
    }
  };

  handleAnimationStart = (e: SyntheticAnimationEvent<*>) => {
    const { animationName } = e;
    if (animationName === autoFillStart) {
      this.setState({ hasValue: true });
    }
  };

  handleChange = (e: SyntheticInputEvent<*>) => {
    this.props.onChange(e.target.value);
  };

  render() {
    const {
      error,
      disabled,
      floatingLabel,
      value,
      focus,
      ellipsis,
      placeholder,
      onChange,
      ...rest
    } = this.props;
    const { hasValue, inFocus } = this.state;
    const active = hasValue || inFocus;

    return (
      <s.Wrapper
        ellipsis={ellipsis}
        active={!!floatingLabel && active}
        error={!!error}
      >
        {
          !!floatingLabel &&
          <FloatingLabel
            active={active}
            error={!!error}
            disabled={disabled}
          >
            {floatingLabel}
          </FloatingLabel>
        }

        <MaskedInput
          {...rest}
          className="app-input-field"
          disabled={disabled}
          value={value}
          focus={focus}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          innerRef={(el) => { this.input = el; }}
          onAnimationStart={this.handleAnimationStart}
          placeholder={placeholder}
          onChange={this.handleChange}
        />

        {
          !!error && !disabled && inFocus &&
          <ValidationTip message={error} />
        }
      </s.Wrapper>
    );
  }

}

export default Input;
