import React from 'react';

export const Clock = props => (
  <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M23.5 12.5v-3h2v3m-3-3.5h4m-2 7.5v9l4 4m4-15l2-2" />
      <path d="M37.5 25.5c0 7.18-5.82 13-13 13s-13-5.82-13-13 5.82-13 13-13 13 5.82 13 13z" />
    </g>
  </svg>
);
