import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

import Cell from '../cell';

const s: StyledComponents = {};

s.SortIcon = styled.div`
  display: inline-flex;
  color: ${ifProp('active', getColor('blueColor'), '#c8cccf')};

  vertical-align: middle;

  transform: scaleY(${ifProp({ sort: 'desc' }, 1, -1)};
  opacity: ${ifProp({ sort: 'none' }, 0, 1)};

  transition: opacity .3s;
`;

s.Wrapper = Cell.withComponent('th').extend`
  font-size: 11px;
  line-height: 1.45;
  font-weight: 700;
  text-align: left;

  cursor: ${ifProp('canSort', 'pointer', 'default')};

  ${ifProp('canSort', css`
    &:hover {
      ${s.SortIcon} {
        opacity: 1;
      }
    }
  `)}
`;

export default s;
