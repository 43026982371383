// @flow
import React, { PureComponent, Fragment } from 'react';

import Page from 'components/page';
import { FloatingButton } from 'components/buttons';
import { Plus } from 'components/icons';
import { ModalProvider } from 'modules/modals';

import CompaniesList from '../companiesList';
import NewCompanyModal from '../newCompanyModal';
import type { Props } from './CompaniesPage.types';

// eslint-disable-next-line react/prefer-stateless-function
export class CompaniesPage extends PureComponent<Props> {

  render() {
    return (
      <Page withActionButton>
        <Page.Container>
          <CompaniesList />
        </Page.Container>


        <ModalProvider>
          {({
            currentModal, openModal, closeModal, modalNames,
          }) => (
            <Fragment>
              <FloatingButton
                onClick={() => openModal(modalNames.NEW_COMPANY)}
                tooltip="Create new company"
              >
                <Plus />
              </FloatingButton>

              <NewCompanyModal
                onCloseRequest={closeModal}
                open={currentModal === modalNames.NEW_COMPANY}
              />
            </Fragment>
          )}
        </ModalProvider>
      </Page>
    );
  }

}

export default CompaniesPage;
