// @flow
export const GET_PROFILE_START = 'modules/auth/GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'modules/auth/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'modules/auth/GET_PROFILE_FAIL';

export const LOGIN_START = 'modules/auth/LOGIN_START';
export const LOGIN_SUCCESS = 'modules/auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'modules/auth/LOGIN_FAIL';

export const LOGOUT_START = 'modules/auth/LOGOUT_START';
export const LOGOUT_SUCCESS = 'modules/auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'modules/auth/LOGOUT_FAIL';

export const GET_SETTINGS_START = 'modules/auth/GET_SETTINGS_START';
export const GET_SETTINGS_SUCCESS = 'modules/auth/GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAIL = 'modules/auth/GET_SETTINGS_FAIL';
