// @flow
import { combineReducers } from 'redux';

import type { Action } from 'shared/types';
import { actionTypesModals } from 'modules/modals';

import * as types from './actionTypes.users';
import type {
  ById,
  Pagination,
  Loading,
  Errors,
} from './types.users';

export const byId = (
  state: ById = {},
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        ...payload.byId,
      };

    case types.INVITE_USER_SUCCESS:
      return {
        ...state,
        [payload.user.id]: payload.user,
      };

    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        [payload.user.id]: payload.user,
      };

    default:
      return state;
  }
};

export const loadingUsers = (
  state: boolean = false,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_USERS_START:
      return true;

    case types.GET_USERS_SUCCESS:
    case types.GET_USERS_FAIL:
      return false;

    default:
      return state;
  }
};

export const pagination = (
  state: Pagination = null,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.RESET_PAGINATION:
      return null;

    case types.GET_USERS_SUCCESS:
      return payload.pagination;

    default:
      return state;
  }
};

export const loading = (
  state: Loading = {},
  { type, payload }: Action,
) => {
  switch (type) {
    case types.INVITE_USER_START:
      return {
        ...state,
        invite: true,
      };

    case types.INVITE_USER_SUCCESS:
    case types.INVITE_USER_FAIL:
    case actionTypesModals.CLOSE_MODAL:
      return {
        ...state,
        invite: false,
      };

    case types.EDIT_USER_START:
      return {
        ...state,
        [payload.userId]: true,
      };

    case types.EDIT_USER_FAIL:
    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        [payload.userId]: false,
      };

    default:
      return state;
  }
};

export const errors = (
  state: Errors = {},
  { type, payload }: Action,
) => {
  switch (type) {
    case types.INVITE_USER_FAIL:
      return {
        ...state,
        invite: payload.error,
      };

    case types.INVITE_USER_SUCCESS:
    case types.INVITE_USER_START:
      return {
        ...state,
        invite: null,
      };

    case types.EDIT_USER_FAIL:
      return {
        ...state,
        [payload.userId]: payload.error,
      };

    case types.EDIT_USER_START:
    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        [payload.userId]: null,
      };

    case actionTypesModals.CLOSE_MODAL:
      return {};

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  loadingUsers,
  pagination,
  loading,
  errors,
});
