// @flow
import styled from 'styled-components';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.table`
  width: 100%;
  table-layout: fixed;

  border-collapse: collapse;
  background: #fff;
`;

s.Header = styled.thead``;

s.Body = styled.tbody`
  position: relative;
`;

export default s;
