// @flow
import styled from 'styled-components';

import type { StyledComponents } from 'shared/types/StyledComponents';

import Logo from 'components/logo';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;

  background-color: #fff;
`;

s.Logo = styled(Logo)`
  position: absolute;
  top: 40px;
  left: 40px;
`;

s.Container = styled.main`
  width: 336px;
`;

export default s;
