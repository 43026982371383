import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectorsUsers, actionsUsers } from '../../redux';
import UserForm from '../UserForm';

const getInitialValues = () => ({
  email: '',
  role: 'admin',
  first_name: '',
  middle_name: '',
  last_name: '',
  initials: '',
});

const getSubmitText = () => 'Invite';

const getDisabledFields = () => ({
  role: true,
});

export default connect(
  createStructuredSelector({
    isSubmitting: selectorsUsers.getInviteUserSubmitting,
    error: selectorsUsers.getInviteUserError,
    initialValues: getInitialValues,
    submitText: getSubmitText,
    disabledFields: getDisabledFields,
  }),
  {
    onSubmit: actionsUsers.inviteUser,
  },
)(UserForm);
