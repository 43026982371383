// @flow
import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';
import { ifProp } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.button`
  ${ifProp('fixed', css`
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 100;
  `)}

  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  padding: 10px;
  border: none;

  color: #fff;

  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background: ${getColor('green')};
  cursor: pointer;
  outline: none;
`;

s.HiddenLabel = styled.span`
  ${hideVisually()}
`;

export default s;
