// @flow
import React, { PureComponent } from 'react';

import type { Props } from './Page.types';
import s from './Page.style';

class Page extends PureComponent<Props> {

  static Header = s.Header;

  static Section = s.Section;

  static Title = s.Title;

  static Container = s.Container;

  render() {
    const { children, withActionButton } = this.props;

    return (
      <s.Wrapper
        withActionButton={withActionButton}
      >
        {children}
      </s.Wrapper>
    );
  }

}

export default Page;
