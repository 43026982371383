// @flow
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Formik } from 'formik';
import yup from 'yup';

import Title from 'components/title';

import { types, selectorsAuth, actionsAuth } from '../redux';
import AuthForm from '../authForm';
import type { Props } from './LoginPage.types';

// eslint-disable-next-line react/prefer-stateless-function
export class LoginPage extends PureComponent<Props> {

  handleSubmit = (data: types.LoginData) => {
    this.props.login(data);
  };

  render() {
    const { isSubmitting, error } = this.props;

    return (
      <Fragment>
        <Title gap={26}>
          Login
        </Title>

        <Formik
          validationSchema={yup.object().shape({
            email: yup.string().required(),
            password: yup.string().required(),
          })}
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={this.handleSubmit}
          render={formikProps => (
            <AuthForm
              {...formikProps}
              isSubmitting={isSubmitting}
              formError={error}
            />
          )}
        />
      </Fragment>
    );
  }

}

const mapStateToProps = createStructuredSelector({
  isSubmitting: selectorsAuth.getLoginLoading,
  error: selectorsAuth.getLoginError,
});

const mapDispatchToProps = {
  login: actionsAuth.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
