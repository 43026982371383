// @flow
import React, { PureComponent } from 'react';
import type { ElementRef } from 'react';
import { Form } from 'formik';

import callAll from 'utils/callAll';

import FormActions from '../formActions';
import ConnectedField from '../connectedField';

import type { Props, State, FormError, Fields } from './BaseForm.types';

const getFormError = (formError: FormError) => {
  if (!formError) return null;

  if (typeof formError === 'string') return formError;

  return formError.form;
};

class BaseForm extends PureComponent<Props, State> {

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.lastFormError === nextProps.formError) return null;

    return {
      formError: getFormError(nextProps.formError),
      lastFormError: nextProps.formError,
    };
  }

  state = {
    formError: getFormError(this.props.formError),
    lastFormError: null, // eslint-disable-line react/no-unused-state
  };

  componentDidMount() {
    this.setErrors(this.props.formError);
  }

  componentDidUpdate(prevProps: Props) {
    const { formError } = this.props;

    if (prevProps.formError !== formError) {
      this.setErrors(formError);
    }
  }

  getFieldProps = (
    { onFocus, fieldRef, ...rest }: { onFocus: Function, fieldRef: Function } = {},
  ) => ({
    ...rest,
    component: ConnectedField,
    onFocus: callAll(onFocus, this.handleFocus),
    fieldRef: (el: ElementRef<*>, name: string) => {
      this.fields[name] = el;

      if (fieldRef) {
        fieldRef(el);
      }
    },
  });

  getSubmitButtonProps = (props: {} = {}) => {
    const { formError } = this.state;
    const { isSubmitting, isValid } = this.props;

    return {
      disabled: !isValid,
      ...props,
      error: formError,
      loading: isSubmitting,
    };
  };

  setErrors(formError: FormError) {
    const { errors, setErrors } = this.props;

    if (!formError) return;

    if (typeof formError === 'string') return;

    this.setFocus(Object.keys(formError).filter((name: string) => name !== 'form')[0]);

    setErrors({
      ...errors,
      ...formError,
    });
  }

  setFocus = (name: string) => {
    const field = this.fields[name];

    if (!field) return;

    field.focus();
  };

  fields: Fields = {};

  handleFocus = () => {
    this.setState((state: State) => {
      if (!state.formError) return null;
      return { formError: null };
    });
  };

  render() {
    const {
      children,
      actions,
    } = this.props;

    return (
      <Form noValidate>
        {children({ getFieldProps: this.getFieldProps })}

        {
          !!actions &&
          <FormActions gap={16}>
            {actions({ getSubmitButtonProps: this.getSubmitButtonProps })}
          </FormActions>
        }
      </Form>
    );
  }

}

export default BaseForm;
