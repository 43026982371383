// @flow
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as routes from 'shared/routes';
import { LoginPage } from 'modules/auth';
import { EmptyLayout } from 'components/layouts';

import type { Props } from './EmptyLayoutRoutes.types';

class EmptyLayoutRoutes extends Component<Props> {

  makeRoute(route: string) {
    const { match } = this.props;
    return `${match.url}${route}`;
  }

  render() {
    return (
      <EmptyLayout>
        <Switch>
          <Route
            exact
            path={this.makeRoute('')}
            render={() => <Redirect to={this.makeRoute(routes.LOGIN)} />}
          />
          <Route
            path={this.makeRoute(routes.LOGIN)}
            component={LoginPage}
          />
        </Switch>
      </EmptyLayout>
    );
  }

}

export default EmptyLayoutRoutes;
