// @flow
import styled, { css } from 'styled-components';
import { ellipsis } from 'polished';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.span`
  ${ellipsis()}

  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;

  display: flex;
  align-items: center;
  height: 100%;

  color: ${(props) => {
    if (props.disabled) return getColor('silver')(props);
    if (props.error) return getColor('brightOrange')(props);
    if (props.active) return getColor('blue')(props);
    return getColor('stone')(props);
  }};

  pointer-events: none;

  transition-duration: .2s;
  transition-property: height, font-size, font-weight, line-height;

  ${props => props.active && css`
    height: 32px; /* Высота уменьшенного плейсхолдера (16px) + вертикальные отступы по 8px */

    font-size: 11px;
    line-height: 1.45;
    font-weight: 500;

    transform: translateY(0);
  `}
`;

export default s;
