// @flow
import styled from 'styled-components';
import { ellipsis } from 'polished';
import { ifProp } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.li`
  display: flex;
  width: 100%;
  padding: 12px 16px;

  font-size: 13px;
  line-height: 1.235;
  color: ${ifProp('selected', '#fff', getColor('defaultText'))};
  text-align: left;

  background: ${ifProp('selected', getColor('blue'))};
  outline: none;
  cursor: pointer;
`;

s.Text = styled.span`
  ${ifProp('oneLine', ellipsis())}
`;

export default s;
