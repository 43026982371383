// @flow
import { request } from 'utils/http';
import * as endpoints from 'shared/endpoints';

import type { User } from '../users.types';
import type { GetUsersResponse, UserFormData } from './types.users';

export const getUsers = (
  companyId: number,
  page: number,
): Promise<{ data: GetUsersResponse }> =>
  request.get(endpoints.makeUsers(companyId), { params: { page } });

export const inviteUser = (
  companyId: number,
  data: UserFormData,
): Promise<{ data: User }> => request.post(endpoints.makeUsers(companyId), data);

export const editUser = (
  userId: number,
  companyId: number,
  data: UserFormData,
): Promise<{ data: User }> => request.put(
  `${endpoints.makeUsers(companyId)}/${userId}`,
  data,
);
