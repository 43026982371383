// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Table from 'components/table';
import { InfiniteScroll } from 'components/helpers';

import { actionsCompanies, selectorsCompanies } from '../redux';
import CompaniesListItem from './companiesListItem';
import type { Props } from './CompaniesList.types';

export const CompaniesList = ({
  companies, getCompanies, canLoadMore, loading,
}:
Props) => (
  <InfiniteScroll
    loadMore={getCompanies}
    canLoadMore={canLoadMore}
    loading={loading}
  >
    <Table
      headerCells={
        <Fragment>
          <Table.HeadCell width={1 / 2}>Name</Table.HeadCell>
          <Table.HeadCell width={1 / 2}>Instance</Table.HeadCell>
        </Fragment>
      }
    >
      {companies.map(item => (
        <CompaniesListItem key={item.id} {...item} />
      ))}
    </Table>
  </InfiniteScroll>
);

export default connect(
  createStructuredSelector({
    companies: selectorsCompanies.getCompaniesList,
    canLoadMore: selectorsCompanies.getCanLoadMore,
    loading: selectorsCompanies.getLoadingList,
  }),
  {
    getCompanies: actionsCompanies.getCompanies,
  },
)(CompaniesList);
