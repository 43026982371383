// @flow
import { combineReducers } from 'redux';

import type { Action } from 'shared/types';
import { actionTypesModals } from 'modules/modals';
import { actionTypesUsers } from 'modules/users';

import * as types from './actionTypes.companies';
import type { ById, Ids, Pagination, NewCompanyError } from './types.companies';

export const byId = (
  state: ById = {},
  { type, payload }: Action,
) => {
  let users: Ids;
  let company: Object;

  switch (type) {
    case types.GET_COMPANIES_SUCCESS:
      return { ...state, ...payload.byId };

    case types.CREATE_COMPANY_SUCCESS:
    case types.GET_COMPANY_DETAILS_SUCCESS:
      company = state[payload.id] || {};
      return {
        ...state,
        [payload.id]: {
          ...company,
          ...payload,
        },
      };

    case types.CHANGE_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload.data,
        },
      };

    case actionTypesUsers.GET_USERS_SUCCESS:
      company = state[payload.companyId] || {};
      users = company.users || [];

      return {
        ...state,
        [payload.companyId]: {
          ...company,
          users: payload.pagination.current_page === 1 ?
            payload.ids :
            [...users, ...payload.ids],
        },
      };

    case actionTypesUsers.INVITE_USER_SUCCESS:
      company = state[payload.companyId] || {};
      users = company.users || [];

      return {
        ...state,
        [payload.companyId]: {
          ...company,
          users: [payload.user.id, ...users],
        },
      };

    default:
      return state;
  }
};

export const ids = (
  state: Ids = [],
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_COMPANIES_SUCCESS:
      return payload.pagination.current_page === 1 ?
        payload.ids :
        [...state, ...payload.ids];

    default:
      return state;
  }
};

export const pagination = (
  state: Pagination = null,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_COMPANIES_SUCCESS:
      return payload.pagination;

    default:
      return state;
  }
};

export const loadingList = (
  state: boolean = false,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_COMPANIES_START:
      return true;

    case types.GET_COMPANIES_SUCCESS:
    case types.GET_COMPANIES_FAIL:
      return false;

    default:
      return state;
  }
};

export const newCompanySubmitting = (
  state: boolean = false,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.CREATE_COMPANY_START:
      return true;

    case types.CREATE_COMPANY_SUCCESS:
    case types.CREATE_COMPANY_FAIL:
    case actionTypesModals.CLOSE_MODAL:
      return false;

    default:
      return state;
  }
};

export const newCompanyError = (
  state: NewCompanyError = null,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.CREATE_COMPANY_FAIL:
      return payload.error;

    case types.CREATE_COMPANY_SUCCESS:
    case types.CREATE_COMPANY_START:
    case actionTypesModals.CLOSE_MODAL:
      return null;

    default:
      return state;
  }
};

export const loadingCompany = (
  state: boolean = true,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_COMPANY_DETAILS_START:
      return true;

    case types.GET_COMPANY_DETAILS_SUCCESS:
    case types.GET_COMPANY_DETAILS_FAIL:
      return false;

    default:
      return state;
  }
};

export const selectedCompany = (
  state: ?number = null,
  { type, payload }: Action,
) => {
  switch (type) {
    case types.GET_COMPANY_DETAILS_START:
      return payload.id;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  ids,
  pagination,
  loadingList,
  newCompanySubmitting,
  newCompanyError,
  loadingCompany,
  selectedCompany,
});
