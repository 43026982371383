import { setLocale } from 'yup/lib/customLocale';

setLocale({
  mixed: {
    required: 'You can\'t leave this empty',
  },
  string: {
    email: 'Incorrect format of email',
  },
});
