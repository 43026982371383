// @flow
import styled from 'styled-components';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  overflow: hidden;
  background: #fff;
`;

export default s;
