// @flow
import { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import { css } from 'utils/domHelpers';

import type { Props } from './Portal.types';

let zIndex = 2000;

class Portal extends PureComponent<Props> {

  componentDidMount() {
    this.initLayer();
  }

  componentWillUnmount() {
    this.destroyLayer();
  }

  getLayerStyle() {
    const { overlay, wrapperStyle } = this.props;

    if (!overlay) {
      return {
        position: 'relative',
        ...wrapperStyle,
      };
    }

    return {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      ...wrapperStyle,
    };
  }

  getTarget() {
    const { overlay } = this.props;
    return overlay ? this.layer : window;
  }

  layer = document.createElement('div');

  handleClick = (e: MouseEvent) => {
    const { onClickOutside, ignoreClickTo, overlay } = this.props;
    const { target } = e;

    if (!onClickOutside || !(target instanceof HTMLElement)) {
      return;
    }

    if (overlay && target === this.layer) {
      onClickOutside(e);
      return;
    }

    if (ignoreClickTo && ignoreClickTo.contains(target)) return;
    if (this.layer.contains(target)) return;

    onClickOutside(e);
  };

  destroyLayer() {
    if (!this.layer) {
      return;
    }

    zIndex -= 1;

    const target = this.getTarget();
    target.removeEventListener('click', this.handleClick);

    if (document.body) {
      document.body.removeChild(this.layer);
    }
  }

  initLayer() {
    zIndex += 1;

    const target = this.getTarget();
    target.addEventListener('click', this.handleClick);

    css(this.layer, { zIndex, ...this.getLayerStyle() });

    if (document.body) {
      document.body.appendChild(this.layer);
    }
  }

  render() {
    const { innerRef } = this.props;

    if (innerRef) {
      innerRef(this.layer);
    }

    return ReactDOM.createPortal(this.props.children, this.layer);
  }

}

export default Portal;
