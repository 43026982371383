// @flow
import React from 'react';

import type { Props } from './EmptyLayout.types';
import s from './EmptyLayout.style';

const EmptyLayout = ({ children }: Props) => (
  <s.Wrapper>
    <s.Logo big />
    <s.Container>
      {children}
    </s.Container>
  </s.Wrapper>
);

export default EmptyLayout;
