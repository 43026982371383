// @flow
import styled from 'styled-components';
import { prop } from 'styled-tools';

import { resetList } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.ul`
  ${resetList}

  width: ${prop('width', '100%')};
  padding: 8px 0;
  max-height: 428px;

  overflow-y: auto;
  background: #fff;
`;

export default s;
