// @flow
import styled from 'styled-components';
import { ellipsis } from 'polished';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.header`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 64px;
  padding: 0 16px;

  color: #fff;

  background-color: ${getColor('darkBg')};
`;

s.Title = styled.h2`
  ${ellipsis()}
  margin: 0;
  min-width: 0;

  font-size: 17px;
  font-weight: 500;
`;

export default s;
