import React from 'react';

/* eslint-disable max-len */
export const Companies = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        id="__companies__a"
        d="M19 8H5V6h14v2zm-2-6H7v2h10V2zM5.694 20h12.612l1.333-8H4.361l1.333 8zM4.361 10h15.278a2 2 0 0 1 1.973 2.329l-1.333 8A2 2 0 0 1 18.306 22H5.694a2 2 0 0 1-1.973-1.671l-1.333-8A2 2 0 0 1 4.361 10z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <mask id="__companies__b" fill="#fff">
        <use xlinkHref="#__companies__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#__companies__a" />
      <g fill="currentColor" mask="url(#__companies__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
