// @flow
import React from 'react';
import Transition from 'react-transition-group/Transition';

import type { Props } from './NotificationTransition.types';

const NotificationTransition = ({
  children, ...props
}:
Props) => (
  <Transition
    {...props}
    timeout={500}
    onEnter={node => node.classList.add('fadeInUp', 'animated')}
    onExit={(node) => {
      node.classList.remove('fadeInUp', 'animated');
      node.classList.add('fadeOutLeft', 'animated');
    }}
  >
    {children}
  </Transition>
);

export default NotificationTransition;
