// @flow
import styled from 'styled-components';
import { prop, ifProp } from 'styled-tools';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  z-index: 100;

  width: ${prop('width')};

  box-shadow: ${ifProp(
    'withShadow',
    '0 2px 8px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15)',
    'none',
  )};
`;

export default s;
