// @flow
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';
import { rgba } from 'polished';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Text = styled.span`
  position: relative;
  z-index: 2;

  visibility: ${ifProp('visible', 'visible', 'hidden')};
  transition: opacity .2s;
`;

s.Wrapper = styled.button`
  position: relative;

  width: ${ifProp('fullWidth', '100%', 'auto')};
  height: ${switchProp('size', {
    s: '32px',
    md: '48px',
  })};
  padding: 0 24px;
  border: none;

  font-family: ${prop('theme.fonts.main')};
  font-weight: 500;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;

  background: ${getColor('blue')};
  border-radius: 2px;
  cursor: pointer;
  outline: none;

  transition: background-color .2s;

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    border-radius: 2px;

    transition: opacity .2s;
    will-change: opacity;
  }

  &::before {
    background: #000;
  }

  &::after {
    background: #fff;
  }

  &:disabled {
    cursor: default;

    ${s.Text} {
      opacity: 0.5;
    }
  }

  &:not(:disabled):hover {
    &::after {
      opacity: 0.05;
    }
  }

  &:not(:disabled):active {
    &::before {
      opacity: 0.15;
    }

    &::after {
      display: none;
    }
  }

  ${props => props.success && css`
    background: ${getColor('green')};
  `}

  ${props => props.contrast && css`
    color: ${getColor('defaultText')};

    background: #fff;

    &::after {
      display: none;
    }

    &:disabled {
      background: rgba(255, 255, 255, 0.65);
    }
  `}

  ${props => props.ghost && css`
    border: 1px solid ${rgba(getColor('defaultText')(props), 0.15)};

    color: ${props.theme.colors.defaultText};

    background: transparent;

    ${s.Text} {
      opacity: 0.75;
    }

    &::before,
    &::after {
      display: none;
    }

    &:disabled {
      ${s.Text} {
        opacity: 0.35;
      }
    }

    &:not(:disabled):hover {
      ${s.Text} {
        opacity: 1;
      }
    }

    &:not(:disabled):active {
      ${s.Text} {
        opacity: 1;
      }

      background-color: ${rgba(getColor('defaultText')(props), 0.2)};
    }
  `}
`;

s.SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

export default s;
