// @flow
import React, { Fragment } from 'react';
import { Field } from 'formik';

import { Button } from 'components/buttons';
import { FormRow, BaseForm } from 'components/formElements';

import type { Props } from './AuthForm.types';

const AuthForm = ({
  isSubmitting,
  ...rest
}:
Props) => (
  <BaseForm
    {...rest}
    children={({ getFieldProps }) => (
      <Fragment>
        <FormRow>
          <Field
            {...getFieldProps({
              type: 'email',
              floatingLabel: 'Login',
              name: 'email',
            })}
          />
        </FormRow>

        <FormRow>
          <Field
            {...getFieldProps({
              type: 'password',
              floatingLabel: 'Password',
              name: 'password',
            })}
          />
        </FormRow>
      </Fragment>
    )}

    actions={({ getSubmitButtonProps }) => (
      <Button
        {...getSubmitButtonProps({
          success: true,
          fullWidth: true,
        })}
      >
        {isSubmitting ?
          'Loading...' :
          'Sign In'
        }
      </Button>
    )}
  />
);

export default AuthForm;
