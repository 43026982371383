// @flow
import React, { Component } from 'react';

import { Arrow } from 'components/icons';
import { ClickOutside } from 'components/helpers';
import Popover from 'components/popover';
import List from 'components/list';

import FloatingLabel from '../floatingLabel';
import ValidationTip from '../validationTip';
import type { Props, State } from './Select.types';
import s from './Select.style';

const getValueText = ({ value, options }: Props) => {
  if (!value) return '';

  const selectedOption = options.find(option => option.id === value);

  if (!selectedOption) return '';

  return selectedOption.text;
};

class Select extends Component<Props, State> {

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.value === prevState.lastValue) return null;
    return {
      lastValue: nextProps.value,
      valueText: getValueText(nextProps),
    };
  }

  static defaultProps = {
    placeholder: '',
  };

  state = {
    valueText: getValueText(this.props),
    inFocus: false,
    lastValue: null, // eslint-disable-line react/no-unused-state
  };

  focus() {
    this.openDropdown();
  }

  blur() {
    this.closeDropdown();
  }

  openDropdown = () => {
    this.setState({ inFocus: true });
  };

  closeDropdown = () => {
    this.setState({ inFocus: false });
  };

  toggleDropdown = () => {
    const { disabled } = this.props;

    if (disabled) return;

    this.setState(state => ({ inFocus: !state.inFocus }));
  };

  handleSelect = (e: SyntheticMouseEvent<*>, id: string) => {
    this.closeDropdown();
    this.props.onChange(id);
  };

  render() {
    const {
      error,
      disabled,
      floatingLabel,
      value,
      options,
      placeholder,
    } = this.props;
    const { valueText, inFocus } = this.state;
    const active = !!valueText;

    return (
      <ClickOutside
        onClickOutside={this.closeDropdown}
      >
        <s.Wrapper>
          <Popover
            width="100%"
            visible={inFocus}
            anchor={({ ref }) => (
              <s.Widget
                disabled={disabled}
                error={!!error}
                onClick={this.toggleDropdown}
                innerRef={ref}
              >
                {
                  !!floatingLabel &&
                  <FloatingLabel
                    active={active}
                    error={!!error}
                    disabled={disabled}
                  >
                    {floatingLabel}
                  </FloatingLabel>
                }

                <s.Value
                  active={!!floatingLabel && active}
                >
                  {valueText || placeholder}
                </s.Value>

                <s.Arrow disabled={disabled} >
                  <Arrow />
                </s.Arrow>

                {
                  !!error && !disabled && inFocus &&
                  <ValidationTip message={error} />
                }
              </s.Widget>
            )}
          >
            <List
              items={options}
              selected={value}
              onItemSelect={this.handleSelect}
            />
          </Popover>
        </s.Wrapper>
      </ClickOutside>
    );
  }

}

export default Select;
