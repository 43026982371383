// @flow
import React, { PureComponent } from 'react';

import type { Props } from './Header.types';
import s from './Header.style';

class Header extends PureComponent<Props> {

  static Title = s.Title;

  render() {
    const { children } = this.props;

    return (
      <s.Wrapper>
        {children}
      </s.Wrapper>
    );
  }

}

export default Header;
