// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import ProtectedRoute from 'components/protectedRoute';
import { actionsAuth, selectorsAuth } from 'modules/auth';
import * as routes from 'shared/routes';

import EmptyLayoutRoutes from './emptyLayoutRoutes';
import AppLayoutRoutes from './appLayoutRoutes';

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component<*> {

  componentDidMount() {
    this.props.getProfile();
  }

  renderRootRoute = () => {
    const { authenticated } = this.props;
    const redirectTo = authenticated ? routes.AUTHENTICATED : routes.NOT_AUTHENTICATED;

    return <Redirect to={redirectTo} />;
  };

  render() {
    return (
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          protection="none"
          render={this.renderRootRoute}
        />
        <ProtectedRoute
          path={routes.NOT_AUTHENTICATED}
          protection="not authenticated"
          component={EmptyLayoutRoutes}
        />
        <ProtectedRoute
          path={routes.AUTHENTICATED}
          protection="authenticated"
          component={AppLayoutRoutes}
        />
      </Switch>
    );
  }

}

const mapStateToProps = createStructuredSelector({
  authenticated: selectorsAuth.isUserAuthenticated,
});

const mapDispatchToProps = {
  getProfile: actionsAuth.getProfile,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
