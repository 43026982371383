// @flow
import React, { Fragment, PureComponent } from 'react';
import { Field, Formik } from 'formik';
import yup from 'yup';

import { Button } from 'components/buttons';

import { BaseForm, FormRow } from 'components/formElements';
import * as formTransforms from 'utils/formTransforms';

import { userRoles } from '../users.config';
import type { Props, UserFormData } from './UserForm.types';

class UserForm extends PureComponent<Props> {

  static defaultProps = {
    disabledFields: {},
  };

  handleSubmit = (data: UserFormData) => {
    const { onSubmit, companyId } = this.props;
    onSubmit(companyId, data);
  };

  render() {
    const {
      isSubmitting,
      error,
      onCancel,
      initialValues,
      submitText,
      disabledFields,
    } = this.props;

    return (
      <Formik
        onSubmit={this.handleSubmit}
        validationSchema={yup.object().shape({
          email: yup.string().required().email(),
          role: yup.string().required(),
        })}
        initialValues={initialValues}
        render={formikProps => (
          <BaseForm
            {...formikProps}
            isSubmitting={isSubmitting}
            formError={error}
            children={({ getFieldProps }) => (
              <Fragment>
                <FormRow>
                  <Field
                    {...getFieldProps({
                      name: 'email',
                      floatingLabel: 'User Email',
                      disabled: disabledFields.email,
                    })}
                  />
                  <Field
                    {...getFieldProps({
                      name: 'role',
                      floatingLabel: 'Role',
                      componentType: 'select',
                      options: userRoles,
                      disabled: disabledFields.role,
                    })}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    {...getFieldProps({
                      name: 'first_name',
                      floatingLabel: 'First Name',
                      disabled: disabledFields.first_name,
                    })}
                  />
                  <Field
                    {...getFieldProps({
                      name: 'last_name',
                      floatingLabel: 'Last Name',
                      disabled: disabledFields.last_name,
                    })}
                  />
                  <Field
                    {...getFieldProps({
                      name: 'middle_name',
                      floatingLabel: 'Middle Name',
                      disabled: disabledFields.middle_name,
                    })}
                  />
                  <Field
                    {...getFieldProps({
                      name: 'initials',
                      floatingLabel: 'Initials',
                      transform: formTransforms.initials,
                      disabled: disabledFields.initials,
                    })}
                  />
                </FormRow>
              </Fragment>
            )}
            actions={({ getSubmitButtonProps }) => (
              <Fragment>
                <Button
                  {...getSubmitButtonProps({
                    success: true,
                  })}
                >
                  {submitText}
                </Button>
                <Button
                  type="button"
                  onClick={onCancel}
                  ghost
                >
                  Cancel
                </Button>
              </Fragment>
            )}
          />
        )}
      />
    );
  }

}

export default UserForm;
