// @flow
import React from 'react';

import type { Props } from './Nav.types';
import s from './Nav.style';

const Nav = ({
  title,
  items,
}:
Props) => (
  <s.Wrapper>
    <s.Title>{title}</s.Title>
    <s.Menu>
      {items.map((item, ind) => (
        <s.Item key={item.link}>
          <s.Link to={item.link}>
            <s.IconWrapper>{item.icon}</s.IconWrapper>
            <s.Text>{item.text}</s.Text>
          </s.Link>
        </s.Item>
      ))}
    </s.Menu>
  </s.Wrapper>
);

Nav.defaultProps = {
  title: 'Navigation',
  items: [],
};

export default Nav;
