// @flow
import React from 'react';

import Modal from 'components/modal';

import NewCompanyForm from '../newCompanyForm';
import type { Props } from './NewCompanyModal.types';

const NewCompanyModal = ({ open, onCloseRequest }: Props) => (
  <Modal
    title="New Company"
    open={open}
    onCloseRequest={onCloseRequest}
  >
    <NewCompanyForm onCancel={onCloseRequest} />
  </Modal>
);

export default NewCompanyModal;
