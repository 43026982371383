import React from 'react';

/* eslint-disable max-len */
export const Save = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M14 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4l-4-4zM9 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H2V2h10v4z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3 3)">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="currentColor" xlinkHref="#a" />
      <g fill="currentColor" mask="url(#b)">
        <path d="M-3-3h24v24H-3z" />
      </g>
    </g>
  </svg>
);
