// @flow
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Row = styled.tr`
  border-bottom: 1px solid ${getColor('globalLines')};

  cursor: ${ifProp('onClick', 'pointer')};
  outline: none;
  background-color: ${ifProp('active', getColor('lightBlue'), '#fff')};
`;

export default s;
