// @flow
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { ellipsis } from 'polished';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

import { fieldStyle } from '../formElements.style';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  position: relative;

  width: 100%;
`;

s.Widget = styled.div`
  ${fieldStyle.base}

  display: flex;
  width: 100%;

  height: ${fieldStyle.defaultHeight};

  cursor: ${ifProp('disabled', 'default', 'pointer')};

  ${ifProp('disabled', fieldStyle.disabled)}

  ${props => !props.error && css`
    &:hover,
    &:focus {
      ${!props.disabled && fieldStyle.hover};
    }

    &:active {
      ${!props.disabled && fieldStyle.pressed};
    }
  `}

  ${props => props.error && css`
    ${fieldStyle.error};
  `}
`;

s.Value = styled.div`
  ${ellipsis()}

  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;

  padding-top: ${ifProp('active', fieldStyle.activeTopPadding, 0)};
  padding-bottom: ${ifProp('active', fieldStyle.activeBottomPadding, 0)};
  padding-left: 16px;
`;

s.Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${fieldStyle.defaultHeight};

  color: ${ifProp('disabled', getColor('silver'), getColor('blue'))};
`;

export default s;
