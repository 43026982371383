// @flow
import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import yup from 'yup';
import omit from 'lodash/omit';

import appConfig from 'config';
import Page from 'components/page';
import { FloatingButton } from 'components/buttons';
import { Save } from 'components/icons';
import * as formatters from 'utils/formatters';
import { ModalProvider } from 'modules/modals';

import { actionsCompanies, selectorsCompanies } from '../redux';
import { selectorsAuth } from '../../auth/redux';
import CompanyUsers from './companyUsers';
import CompanyInfo from './companyInfo';
import type { Props, CompanyFormData } from './CompanyDetailsPage.types';

export class CompanyDetailsPage extends PureComponent<Props> {

  componentDidMount() {
    const { getCompanyDetails } = this.props;

    getCompanyDetails(this.getCompanyId());
  }

  getCompanyId() {
    const { match } = this.props;

    return parseInt(match.params.id, 10);
  }

  handleSubmit = (data: CompanyFormData) => {
    const { changeCompany } = this.props;
    let parsedData = {
      ...data,
      instance: formatters.getInstance(data.instance),
    };

    changeCompany(this.getCompanyId(), parsedData);
  };

  render() {
    const { initialValues, languages } = this.props;

    return (
      <Page withActionButton>
        <Page.Container>
          <Formik
            enableReinitialize
            onSubmit={this.handleSubmit}
            validationSchema={yup.object().shape({
              name: yup.string().required(),
              instance: yup.string().required(),
              lang: yup.string().required(),
            })}
            initialValues={initialValues}
            render={() => (
              <Form noValidate>
                <CompanyInfo languages={languages} />
                <FloatingButton
                  tooltip="Save changes"
                >
                  <Save />
                </FloatingButton>
              </Form>
            )}
          />

          <ModalProvider>
            {modalProps => (
              <CompanyUsers {...modalProps} id={this.getCompanyId()} />
            )}
          </ModalProvider>
        </Page.Container>
      </Page>
    );
  }

}

export default connect(
  createStructuredSelector({
    initialValues: selectorsCompanies.getCompanyFormInitialValues,
    languages: selectorsAuth.getLanguages,
  }),
  {
    getCompanyDetails: actionsCompanies.getCompanyDetails,
    changeCompany: actionsCompanies.changeCompanyData,
  },
)(CompanyDetailsPage);
