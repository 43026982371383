// @flow
import type { ComponentType } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';

import type { Props } from './Title.types';

const SIZE_MAP = {
  s: 19,
  m: 21,
  l: 23,
};

const Title: ComponentType<Props> = styled.h2`
  margin: 0;
  margin-bottom: ${prop('gap')}px;

  font-size: ${props => typeof props.size === 'string' ?
    SIZE_MAP[props.size]
    : props.size}px;
  font-weight: normal;
  color: ${getColor('defaultText')};
`;

Title.defaultProps = {
  gap: 0,
  size: 'l',
};

export default Title;
