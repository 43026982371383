// @flow
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import Table from 'components/table';
import * as routes from 'shared/routes';
import * as formatters from 'utils/formatters';

import type { Props } from './CompaniesListItem.types';

class CompaniesListItem extends PureComponent<Props> {

  handleClick = () => {
    const { id, history } = this.props;
    history.push(routes.makeAuthenticatedRoute(`${routes.COMPANIES}/${id}`));
  };

  render() {
    const { name, instance } = this.props;

    return (
      <Table.Row
        onClick={this.handleClick}
      >
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{formatters.instance(instance)}</Table.Cell>
      </Table.Row>
    );
  }

}

export default withRouter(CompaniesListItem);
