// @flow
import styled, { css } from 'styled-components';
import { ifProp, withProp } from 'styled-tools';
import { rgba } from 'polished';

import type { StyledComponents } from 'shared/types/StyledComponents';
import { getColor } from 'utils/styleHelpers';

const s: StyledComponents = {};

s.Wrapper = styled.button`
  padding: 8px;
  border: none;

  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => {
    if (props.primary) return getColor('blue')(props);
    if (props.danger) return getColor('red')(props);
    return getColor('stone')(props);
  }};

  background: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    color: ${getColor('defaultText')};

    opacity: 0.35;
  }

  ${ifProp('filled', css`
    &:not(:disabled) {
      background-color: ${withProp(getColor('defaultText'), color => rgba(color, 0.05))};

      &:hover {
        background-color: ${withProp(getColor('defaultText'), color => rgba(color, 0.1))};
      }
  `)}
`;

export default s;
