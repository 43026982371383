import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectorsUsers, actionsUsers } from '../../redux';
import UserForm from '../UserForm';

const getSubmitText = () => 'Edit';

export default connect(
  () => createStructuredSelector({
    isSubmitting: selectorsUsers.makeGetUserLoading(),
    error: selectorsUsers.makeGetUserErrors(),
    initialValues: selectorsUsers.makeGetUserById(),
    submitText: getSubmitText,
  }),
  (dispatch, props) => ({
    onSubmit: (companyId, data) =>
      dispatch(actionsUsers.editUser(props.userId, companyId, data)),
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    disabledFields: {
      role: stateProps.initialValues.role === 'owner',
      email: true,
    },
  }),
)(UserForm);
