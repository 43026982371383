// @flow
import React from 'react';

import Tooltip from 'components/tooltip';

import type { Props } from './FloatingButton.types';
import s from './FloatingButton.style';

const FloatingButton = ({
  tooltip, children, fixed, ...rest
}:
Props) => (
  <Tooltip
    position="left"
    offset={16}
    text={tooltip}
  >
    {({ ref, getAnchorProps }) => (
      <s.Wrapper
        innerRef={ref}
        {...getAnchorProps({
          fixed,
          ...rest,
        })}
      >
        <s.HiddenLabel>{tooltip}</s.HiddenLabel>
        {children}
      </s.Wrapper>
    )}
  </Tooltip>
);

FloatingButton.defaultProps = {
  fixed: true,
};

export default FloatingButton;
