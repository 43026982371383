// @flow
import type { Company, NewCompanyData } from '../companies.types';
import * as types from './actionTypes.companies';
import type {
  ById, Ids, Pagination, NewCompanyError, ChangeCompanyData,
} from './types.companies';

export const getCompanies = (page: number) => ({
  type: types.GET_COMPANIES_START,
  payload: { page },
});

export const getCompaniesSuccess = (
  payload: { byId: ById, ids: Ids, pagination: Pagination },
) => ({
  type: types.GET_COMPANIES_SUCCESS,
  payload,
});

export const getCompaniesFail = (error: string) => ({
  type: types.GET_COMPANIES_FAIL,
  payload: { error },
});

export const createCompany = (payload: NewCompanyData) => ({
  type: types.CREATE_COMPANY_START,
  payload,
});

export const createCompanySuccess = (payload: Company) => ({
  type: types.CREATE_COMPANY_SUCCESS,
  payload,
});

export const createCompanyFail = (error: NewCompanyError) => ({
  type: types.CREATE_COMPANY_FAIL,
  payload: { error },
});

export const createCompanyCancel = () => ({
  type: types.CREATE_COMPANY_CANCEL,
});

export const getCompanyDetails = (id: number) => ({
  type: types.GET_COMPANY_DETAILS_START,
  payload: { id },
});

export const getCompanyDetailsSuccess = (payload: Company) => ({
  type: types.GET_COMPANY_DETAILS_SUCCESS,
  payload,
});

export const getCompanyDetailsFail = (error: string) => ({
  type: types.GET_COMPANY_DETAILS_FAIL,
  payload: { error },
});

export const changeCompanyData = (
  id: number, data: ChangeCompanyData,
) => ({
  type: types.CHANGE_COMPANY_DATA_START,
  payload: { id, data },
});

export const changeCompanyDataSuccess = (
  id: number,
  data: ChangeCompanyData,
) => ({
  type: types.CHANGE_COMPANY_DATA_SUCCESS,
  payload: { id, data },
});

export const changeCompanyDataFail = (
  id: number, error: string,
) => ({
  type: types.CHANGE_COMPANY_DATA_FAIL,
  payload: { id, error },
});
