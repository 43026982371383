// @flow
import React, { PureComponent } from 'react';

import Cell from './cell';
import HeadCell from './headCell';
import Row from './row';
import type { Props } from './Table.types';
import s from './Table.style';

class Table extends PureComponent<Props> {

  static Cell = Cell;

  static Row = Row;

  static HeadCell = HeadCell;

  render() {
    const { headerCells, children } = this.props;

    return (
      <s.Wrapper>
        {
          !!headerCells &&
          <s.Header>
            <Row withHover={false}>{headerCells}</Row>
          </s.Header>
        }
        <s.Body>
          {children}
        </s.Body>
      </s.Wrapper>
    );
  }

}

export default Table;
