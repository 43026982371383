// @flow
import React, { Component } from 'react';

import type { Props } from './ClickOutside.types';
import s from './ClickOutside.style';

class ClickOutside extends Component<Props> {

  componentDidMount() {
    document.addEventListener('click', this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
  }

  getRef = (ref: ?HTMLElement) => {
    this.wrapper = ref;
  };

  wrapper: ?HTMLElement;

  handleClick = (e: MouseEvent) => {
    const { onClickOutside } = this.props;

    if (!this.wrapper || !this.wrapper.contains((e.target: any))) {
      onClickOutside(e);
    }
  }

  render() {
    const { children } = this.props;

    if (typeof children === 'function') {
      return children({ ref: this.getRef });
    }

    return (
      <s.Wrapper innerRef={this.getRef}>
        {children}
      </s.Wrapper>
    );
  }

}

export default ClickOutside;
