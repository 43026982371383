import React from 'react';

export const Done = props => (
  <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="bevel"
      strokeWidth={2}
      d="M10 22.949l10.683 10.974L39.198 15"
    />
  </svg>
);
