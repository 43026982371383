// @flow
import styled from 'styled-components';

import { getColor } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

s.Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
`;

s.Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  background: ${getColor('lightGray')};
  overflow-y: auto;
`;

export default s;
