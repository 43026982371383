// @flow
import * as types from './actionTypes.auth';
import type { Profile, LoginData, LoginError } from './types.auth';

export const getProfile = () => ({
  type: types.GET_PROFILE_START,
});

export const getProfileSuccess = (payload: Profile) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload,
});

export const getProfileFail = (error: string) => ({
  type: types.GET_PROFILE_FAIL,
  payload: { error },
});

export const login = (data: LoginData) => ({
  type: types.LOGIN_START,
  payload: { data },
});

export const loginSuccess = (payload: Profile) => ({
  type: types.LOGIN_SUCCESS,
  payload,
});

export const loginFail = (error: LoginError) => ({
  type: types.LOGIN_FAIL,
  payload: { error },
});

export const logout = () => ({
  type: types.LOGOUT_START,
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

export const logoutFail = (error: string) => ({
  type: types.LOGOUT_FAIL,
  payload: { error },
});

export const getSettings = () => ({
  type: types.GET_SETTINGS_START,
});

export const getSettingsSuccess = (payload: Profile) => ({
  type: types.GET_SETTINGS_SUCCESS,
  payload,
});

export const getSettingsFail = (error: string) => ({
  type: types.GET_SETTINGS_FAIL,
  payload: { error },
});
