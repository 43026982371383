// @flow
import React from 'react';

type Props = {
  sortDirection: 'asc' | 'desc',
};

/* eslint-disable max-len */
export const Sort = ({ sortDirection, ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <title>Sorting directions is: {sortDirection}</title>
    <defs>
      <path id="__sort-icon-1__" d="M18 9H6V7h12v2zm-4 4H6v-2h8v2zm-4 4H6v-2h4v2z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <mask id="__sort-icon-2__" fill="#fff">
        <use xlinkHref="#__sort-icon-1__" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#__sort-icon-1__" />
      <g fill="currentColor" mask="url(#__sort-icon-2__)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
