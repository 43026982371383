// @flow
import React, { PureComponent } from 'react';

import { userName } from 'utils/formatters';

import type { Props } from './Avatar.types';
import s from './Avatar.style';

class Avatar extends PureComponent<Props> {

  static defaultProps = {
    reserve: 'Anonymous',
    theme: 'blue',
  };

  getThreeLetters(str: string) {
    if (!str) return '';
    return str.substr(0, 3);
  }

  getFirstLetter(str: ?string) {
    if (!str) return '';
    return str[0];
  }

  getTitle() {
    const { firstName, lastName, reserve } = this.props;

    return userName(firstName, lastName) || reserve;
  }

  getText() {
    const { initials, reserve } = this.props;

    if (initials) return this.getThreeLetters(initials);

    const computedInitials = this.makeInitials();

    if (computedInitials) return computedInitials;

    return this.getFirstLetter(reserve);
  }

  makeInitials() {
    const { firstName, lastName, middleName } = this.props;
    const first = this.getFirstLetter(firstName);
    const second = this.getFirstLetter(middleName);
    const third = this.getFirstLetter(lastName);

    return `${first}${second}${third}`;
  }

  render() {
    const {
      initials,
      reserve,
      theme,
      firstName,
      middleName,
      lastName,
      ...rest
    } = this.props;
    const text = this.getText();

    return (
      <s.Wrapper
        blue={theme === 'blue'}
        white={theme === 'white'}
        small={text.length > 2}
        title={this.getTitle()}
        {...rest}
      >
        {text}
      </s.Wrapper>
    );
  }

}

export default Avatar;
