// @flow
import type { ComponentType } from 'react';
import styled from 'styled-components';
import { ellipsis } from 'polished';
import { ifProp } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';

import type { Props } from './Cell.types';

const Cell: ComponentType<Props> = styled.td`
  ${ellipsis()}

  display: table-cell;
  width: ${({ width, actions }) => {
    if (actions) return '48px';

    if (!width) return 'auto';

    if (Number.isInteger(width)) {
      return `${width}px`;
    }

    return `${width * 100}%`;
  }};
  height: 40px;
  padding-left: ${(props) => {
    if (props.actions) return 8;
    if (props.leftGap === undefined) return 16;
    return props.leftGap;
  }}px;
  padding-right: ${(props) => {
    if (props.actions) return 8;
    if (props.rightGap === undefined) return 16;
    return props.rightGap;
  }}px;

  font-size: 13px;
  line-height: 1.23;
  color: ${getColor('defaultText')};
  vertical-align: middle;

  opacity: ${ifProp('blocked', 0.5, 1)};
  cursor: ${ifProp('onClick', 'pointer')};
  `;

export default Cell;
