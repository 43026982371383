// @flow
import React, { PureComponent } from 'react';

import Popover from 'components/popover';
import { Dots } from 'components/icons';
import List from 'components/list';
import { ClickOutside } from 'components/helpers';

import type { Props, State } from './ContextMenu.types';
import s from './ContextMenu.style';

class ContextMenu extends PureComponent<Props, State> {

  static defaultProps = {
    theme: 'gray',
    visible: true,
  };

  state = {
    active: false,
  };

  closePopover() {
    this.setState((state: State) => {
      if (!state.active) return null;
      return { active: false };
    });
  }

  toggleState() {
    this.setState((state: State) => ({ active: !state.active }));
  }

  handleButtonClick = () => {
    this.toggleState();
  };

  handleClickOutside = () => {
    this.closePopover();
  };

  handleListItemSelect = (e: SyntheticMouseEvent<*>, id: string | number) => {
    const { onItemSelect } = this.props;

    this.closePopover();

    if (onItemSelect) {
      onItemSelect(e, id);
    }
  };

  render() {
    const {
      items,
      theme,
      size,
      visible,
    } = this.props;
    const { active } = this.state;

    return (
      <ClickOutside
        onClickOutside={this.handleClickOutside}
      >
        <Popover
          placement="bottom-start"
          visible={active}
          anchor={({ ref }) => (
            <s.Button
              colorTheme={theme}
              active={active}
              size={size}
              onClick={this.handleButtonClick}
              visible={visible}
            >
              <s.HoverArea
                colorTheme={theme}
                active={active}
                innerRef={ref}
              >
                <Dots />
              </s.HoverArea>
            </s.Button>
          )}
        >
          <List
            width="256px"
            items={items}
            oneLine
            onItemSelect={this.handleListItemSelect}
          />
        </Popover>
      </ClickOutside>
    );
  }

}

export default ContextMenu;
