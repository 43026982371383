// @flow
import styled from 'styled-components';

import Title from 'components/title';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

s.Title = Title.withComponent('legend');

export default s;
