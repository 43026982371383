// @flow
import styled from 'styled-components';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.SpinnerWrapper = styled.ul`
  padding: 16px;
`;

export default s;
