// @flow
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { getColor } from 'utils/styleHelpers';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding-top: 1px;

  font-size: ${ifProp('small', '8px', '10px')};
  font-weight: 500;
  text-transform: uppercase;

  border-radius: 50%;
  overflow: hidden;
  background: #fff;

  ${ifProp('blue', css`
    border: 1px solid;

    color: ${getColor('blue')};
  `)}

  ${ifProp('white', css`
    color: ${getColor('defaultText')};
  `)}
`;

export default s;
