// @flow
import styled from 'styled-components';
import { rgba } from 'polished';

import { getColor, animations } from 'utils/styleHelpers';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  max-width: 200px;
  padding: 4px 8px;

  text-align: center;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;

  background-color: ${props => rgba(getColor('defaultText')(props), 0.9)};
  border-radius: 2px;

  animation: ${animations.fadeIn} ease-out 150ms;
`;

export default s;
