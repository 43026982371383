// @flow
import styled, { css } from 'styled-components';

import type { StyledComponents } from 'shared/types/StyledComponents';
import { getColor } from 'utils/styleHelpers';
import { ifProp, prop } from 'styled-tools';

const s: StyledComponents = {};

const colorsMap = {
  success: 'green',
  error: 'error',
  warning: 'yellow',
  primary: 'blue',
};

const MAX_WIDTH = '384px';

const getBgColor = props => getColor(colorsMap[props.type])(props);

s.NotificationBar = styled.div`
  display: flex;
  max-width: ${MAX_WIDTH};
  width: ${ifProp('mini', 'auto', MAX_WIDTH)};

  font-family: ${prop('theme.fonts.main')};
  overflow: hidden;
  cursor: default;

  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15),
              0 0 2px 0 rgba(0, 0, 0, 0.15);
`;

s.NotificationBookmark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  flex-shrink: 0;

  color: #fff;

  background: ${getBgColor};
`;

s.NotificationBarTitle = styled.h4`
  margin: 0;

  font-size: 19px;
  line-height: 1.3;
  color: ${getColor('defaultText')};
  font-weight: normal;

  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

s.NotificationBarMessage = styled.p`
  margin: 0;

  font-size: 13px;
  line-height: 1.2;
  color: ${getColor('stone')};

  &:not(:last-child) {
    margin-bottom: 29px;
  }
`;

s.NotificationBarBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${ifProp('mini', '20px 24px 20px 8px', '22px 16px 15px')};

  overflow: hidden;
  background: ${ifProp('mini', getBgColor, '#fff')};

  ${ifProp('mini', css`
    ${s.NotificationBarTitle} {
      color: #fff;
    }
  `)}
`;

s.NotificationBarActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;

export default s;
