// @flow
import type { User } from '../users.types';
import * as types from './actionTypes.users';
import type {
  ById, Ids, Pagination, UserFormData,
} from './types.users';

export const getUsers = (companyId: number, page: number) => ({
  type: types.GET_USERS_START,
  payload: { companyId, page },
});

export const getUsersSuccess = (
  payload: { byId: ById, ids: Ids, pagination: Pagination, companyId: number },
) => ({
  type: types.GET_USERS_SUCCESS,
  payload,
});

export const getUsersFail = (error: string) => ({
  type: types.GET_USERS_FAIL,
  payload: { error },
});

export const inviteUser = (companyId: number, data: UserFormData) => ({
  type: types.INVITE_USER_START,
  payload: {
    companyId,
    data,
  },
});

export const inviteUserSuccess = (
  companyId: number,
  user: User,
) => ({
  type: types.INVITE_USER_SUCCESS,
  payload: { companyId, user },
});

export const inviteUserFail = (companyId: number, error: string) => ({
  type: types.INVITE_USER_FAIL,
  payload: { companyId, error },
});

export const editUser = (
  userId: number,
  companyId: number,
  data: UserFormData,
) => ({
  type: types.EDIT_USER_START,
  payload: {
    userId,
    companyId,
    data,
  },
});

export const editUserSuccess = (userId: number, user: User) => ({
  type: types.EDIT_USER_SUCCESS,
  payload: { user, userId },
});

export const editUserFail = (userId: number, error: string) => ({
  type: types.EDIT_USER_FAIL,
  payload: { userId, error },
});

export const resetPagination = () => ({
  type: types.RESET_PAGINATION,
});
