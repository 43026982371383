// @flow
import React from 'react';

import { FlatButton } from 'components/buttons';

import NotificationIcon from './notificationIcon';
import type { Props } from './Notification.types';
import s from './Notification.style';

const Notification = ({
  type,
  title,
  message,
  actions,
  icon,
  mini,
  close,
}:
Props) => (
  <s.NotificationBar
    mini={mini}
  >
    <s.NotificationBookmark type={type}>
      <NotificationIcon icon={icon} />
    </s.NotificationBookmark>

    <s.NotificationBarBody
      mini={mini}
      type={type}
    >
      <s.NotificationBarTitle>
        {title}
      </s.NotificationBarTitle>

      {
        !!message && !mini &&
        <s.NotificationBarMessage>
          {message}
        </s.NotificationBarMessage>
      }

      {
        !mini &&
        <s.NotificationBarActions>
          {
            actions === undefined &&
            <FlatButton
              onClick={close}
            >
              Close
            </FlatButton>
          }
          {
            !!actions &&
            actions({ close })
          }
        </s.NotificationBarActions>
      }

    </s.NotificationBarBody>
  </s.NotificationBar>
);

Notification.defaultProps = {
  type: 'warning',
  icon: 'attention',
};

export default Notification;
