// @flow
import styled, { css } from 'styled-components';

import { shadowBorder } from 'utils/styleHelpers';
import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

const border = css`
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  pointer-events: none;
`;

const makeBorders = (props, notFirst = false) => shadowBorder({
  top: notFirst ? false : props.topBorder,
  left: props.leftBorder,
  bottom: props.bottomBorder,
  right: props.rightBorder,
});

s.Row = styled.div`
  position: relative;

  display: flex;

  &::after {
    ${props => makeBorders(props)}
    ${border}
  }

  &:not(:first-of-type) {
    &::after {
      ${props => makeBorders(props, true)}
    }
  }
`;

s.Item = styled.div`
  position: relative;

  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
  width: 0;

  &:not(:first-of-type) {
    &::after {
      ${shadowBorder({ right: false, top: false, bottom: false })}
      ${border}
    }
  }
`;

export default s;
