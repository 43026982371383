// @flow
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { ellipsis } from 'polished';

import { getColor } from 'utils/styleHelpers';

import type { StyledComponents } from 'shared/types/StyledComponents';

const s: StyledComponents = {};

s.Wrapper = styled.div`
  padding: 16px 50px;
  padding-top: 16px;
  padding-bottom: ${ifProp('withActionButton', 128, 16)}px;
`;

s.Section = styled.section`
  background: #fff;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

s.Header = styled.header`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  border-bottom: 1px solid ${getColor('globalLines')};

  ${ifProp('withActions', css`
    justify-content: space-between;
    padding-right: 0;
  `)}
`;

s.Title = styled.h3`
  ${ellipsis()}

  margin: 0;
  min-width: 0;

  font-size: 17px;
  font-weight: 300;
  font-family: ${props => props.theme.fonts.robotoRegular};
  color: ${props => props.theme.colors.defaultTextColor};
`;

s.Container = styled.div`
  min-width: 924px;
  max-width: 1152px;
  width: 100%;
  margin: 0 auto;
`;

export default s;
