import React from 'react';

export const Attention = props => (
  <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={24} cy={24} r={16} stroke="currentColor" strokeWidth={2} />
      <path fill="currentColor" d="M23 16h2v12h-2zm0 14h2v2h-2z" />
    </g>
  </svg>
);
