// @flow
import React from 'react';

const DIRECTIONS = {
  top: '-180deg',
  bottom: '0',
  left: '-90deg',
  right: '90deg',
};

type Props = {
  style?: Object,
  direction: 'top' | 'bottom' | 'left' | 'right',
};

export const Arrow = ({ style, direction, ...rest }: Props) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    style={{ transform: `rotate(${DIRECTIONS[direction]})`, ...style }}
    {...rest}
  >
    <defs>
      <path id="__arrow__a" d="M16.6 9L12 13.6 7.4 9 6 10.4l6 6 6-6z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="__arrow__b" fill="#fff">
        <use xlinkHref="#__arrow__a" />
      </mask>
      <use fill="currentColor" xlinkHref="#__arrow__a" />
      <g fill="currentColor" mask="url(#__arrow__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

Arrow.defaultProps = {
  direction: 'down',
  style: null,
};
