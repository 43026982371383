import React, { PureComponent } from 'react';

import s from './Row.style';
import type { Props, State, Active } from './Row.types';

class Row extends PureComponent<Props, State> {

  static defaultProps = {
    withHover: true,
  };

  state = {
    active: false,
  };

  enableActive = (reason: Active = 'hover') => {
    this.setState((state) => {
      if (state.active) return null;
      return { active: reason };
    });
  }

  disableActive = () => {
    this.setState((state) => {
      if (!state.active) return null;
      return { active: false };
    });
  }

  toggleActive = (active: Active) => {
    if (active) this.enableActive(active);
    else this.disableActive();
  };

  handleHover = () => {
    const { withHover } = this.props;

    if (withHover) {
      this.enableActive();
    }
  };

  handleHoverOff = () => {
    const { withHover } = this.props;

    if (withHover) {
      this.disableActive();
    }
  };

  render() {
    const { active } = this.state;
    const { withHover, children, ...rest } = this.props;

    return (
      <s.Row
        {...rest}
        onMouseOver={this.handleHover}
        onMouseOut={this.handleHoverOff}
        onFocus={this.handleHover}
        onBlur={this.handleHoverOff}
        tabIndex={withHover ? '0' : '-1'}
        active={active}
      >
        {typeof children === 'function' ?
          children({
            active,
            toggleActive: this.toggleActive,
          })
          :
          children
        }
      </s.Row>
    );
  }

}

export default Row;
