// @flow
import React from 'react';

import type { Props } from './Spinner.types';
import s from './Spinner.style';

const Spinner = ({
  animationSpeed,
  className,
  fillColor,
  size,
  style,
  center,
}:
Props) => (
  <s.Wrapper
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    size={size}
    speed={animationSpeed}
    style={style}
    className={className}
    center={center}
  >
    <defs>
      <path
        id="__spinner__"
        d="M12 4h-.008C7.576 4 4 7.584 4 12s3.576 8 7.992 8C16.416
        20 20 16.416 20 12h-2c0 3.315-2.685 6-6 6s-6-2.685-6-6 2.685-6 6-6V4z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="__spinner-mask__" fill="#fff">
        <use xlinkHref="#__spinner__" />
      </mask>
      <g fill={fillColor} mask="url(#__spinner-mask__)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </s.Wrapper>
);

Spinner.defaultProps = {
  animationSpeed: 600,
  className: null,
  fillColor: '#4183d7',
  size: 32,
  style: null,
};

export default Spinner;
