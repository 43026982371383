// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Table from 'components/table';
import { InfiniteScroll } from 'components/helpers';

import { actionsUsers, selectorsUsers } from '../redux';
import Item from './usersListItem';
import type { Props } from './UsersList.types';

export const UsersList = ({
  getUsers,
  canLoadMore,
  loading,
  users,
  onEditUser,
}:
Props) => (
  <InfiniteScroll
    loadMore={getUsers}
    canLoadMore={canLoadMore}
    loading={loading}
  >
    <Table
      headerCells={
        <Fragment>
          <Table.HeadCell width={1 / 4}>First name</Table.HeadCell>
          <Table.HeadCell width={1 / 4}>Last name</Table.HeadCell>
          <Table.HeadCell width={1 / 4}>Email</Table.HeadCell>
          <Table.HeadCell width={1 / 4}>Role</Table.HeadCell>
        </Fragment>
      }
    >
      {users.map(user => (
        <Item
          key={user.id}
          user={user}
          onEdit={onEditUser}
        />
      ))}
    </Table>
  </InfiniteScroll>
);


export default connect(
  createStructuredSelector({
    canLoadMore: selectorsUsers.getCanLoadMore,
    loading: selectorsUsers.getLoadingUsers,
  }),
  (dispatch: Dispatch<*>, props: Props) => ({
    getUsers: (page: number) => dispatch(actionsUsers.getUsers(props.companyId, page)),
  }),
)(UsersList);
