// @flow
import { request } from 'utils/http';
import * as endpoints from 'shared/endpoints';

import type { LoginData } from './types.auth';

export const login = (data: LoginData) => request.post(endpoints.LOGIN, data);
export const logout = () => request.post(endpoints.LOGOUT);
export const getProfile = () => request.get(endpoints.PROFILE);
export const getSettings = () => request.get(endpoints.SETTINGS);
