// @flow
import type { User } from '../users.types';
import type {
  ById, Ids,
} from './types.users';

export const normalizeUsersList = (data: User[]): {
  byId: ById,
  ids: Ids,
} => data.reduce(
  (acc, item) => {
    acc.byId[item.id] = item;
    acc.ids.push(item.id);
    return acc;
  },
  { byId: {}, ids: [] },
);
